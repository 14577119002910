import React, { useEffect, useState } from 'react'
import _extendLayout from '../../layout/_extendLayout';
import { CardHeader, CardContent, Grid, Button, Divider, CardActions, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { required, TextInput, SimpleForm, SelectInput, email, useDataProvider, BooleanInput, useNotify } from "react-admin";
import PasswordChecklist from "react-password-checklist";
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import PaymentMethodForm from '../PaymentMethodForm';
import { useNavigate } from 'react-router-dom';

const ClientView = ({ record, handleChange, access, handleSave, emailError }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    
    const { isOwner } = record;
    
    const handlePasswordChange = e => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newPasswordAgain = e.target.value;
        setConfirmPassword(newPasswordAgain);
    };

    const passwordValidation = () => {
        if (!isPasswordValid) {
        return 'Please create a password that fulfills the below criteria.';
        } else {
        return undefined;
        }
    };

    const validatePassword = [required('Please enter a secure password.'), passwordValidation];
    const validatePasswordAgain = [required('Please re-enter the password.'), passwordValidation];
    
    if(!record) {
        return <p>Loading...</p>
    }

    return (
        <>
            <_extendLayout>
                <SimpleForm toolbar={null} onSubmit={handleSave}>
                    <CardHeader title="Create User" />
                    <CardContent style={{ minWidth: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextInput 
                                    source="firstName" 
                                    label="First Name"
                                    fullWidth
                                    validate={required("Please enter a first name.")}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput 
                                    label="Last name" 
                                    source="lastName" 
                                    fullWidth
                                    validate={required('Please enter a last name.')}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Email Address"
                                    source="email"
                                    type="email"
                                    fullWidth
                                    validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
                                    defaultValue=""
                                    error={!!emailError}
                                    helperText={emailError}
                                    FormHelperTextProps={{ style: { color: 'red' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Password"
                                    source="password"
                                    type="password"
                                    fullWidth
                                    value={password || ""}
                                    defaultValue={password || ""}
                                    validate={validatePassword}
                                    onChange={handlePasswordChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Confirm Password"
                                    source="passwordAgain"
                                    type="password"
                                    fullWidth
                                    value={confirmPassword || ""}
                                    onChange={handleConfirmPasswordChange}
                                    defaultValue={confirmPassword || ""}
                                    validate={validatePasswordAgain}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <PasswordChecklist
                                rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                                minLength={8}
                                value={password}
                                valueAgain={confirmPassword}
                                onChange={isValid => {
                                    setIsPasswordValid(isValid);
                                }}
                            />
                            </Grid>
                        </Grid>
                    </CardContent>

                    {isOwner && (
                        <>
                            <CardHeader title="Company details" />
                            <CardContent style={{ minWidth: "100%" }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            label="Company name"
                                            source="client.companyName"
                                            validate={required('Please enter a company name.')}
                                            fullWidth
                                            defaultValue=""
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            label="Company registration no."
                                            source="client.companyRegNumber"
                                            validate={required('Please enter a company registration no.')}
                                            fullWidth
                                            defaultValue=""
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput 
                                            label="Company address" 
                                            source="client.companyAddress"
                                            fullWidth
                                            defaultValue=""
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h6">Grant Access</Typography>
                                        <Typography gutterBottom variant="body2">Select to allow user access to modules</Typography>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={access.vendingMachine}
                                                        onChange={(e) => handleChange(e)}
                                                        name="vendingMachine"
                                                    />
                                                }
                                                label="Vending Machine"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={access.inventory}
                                                        onChange={(e) => handleChange(e)}
                                                        name="inventory"
                                                    />
                                                }
                                                label="Inventory"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={access.salesReport}
                                                        onChange={(e) => handleChange(e)}
                                                        name="salesReport"
                                                    />
                                                }
                                                label="Sales Report"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={access.user}
                                                        onChange={(e) => handleChange(e)}
                                                        name="user"
                                                    />
                                                }
                                                label="User"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={access.mro}
                                                        onChange={(e) => handleChange(e)}
                                                        name="mro"
                                                    />
                                                }
                                                label="MRO"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={access.mroReport}
                                                        onChange={(e) => handleChange(e)}
                                                        name="mroReport"
                                                    />
                                                }
                                                label="MRO Report"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </>
                    )}
                    <Divider />
                    <CardActions>
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: "2rem"}}>
                            Save
                        </Button>
                    </CardActions>
                </SimpleForm>
            </_extendLayout>
        </>
    );
};

const UsersCreate = () => {
    const dataProvider = useDataProvider();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("admin");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [paymentData, setPaymentData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [currentRole, setCurrentRole] = useState("");
    const [userCreationInProgress, setUserCreationInProgress] = useState(false);
    const notify = useNotify();
    const navigate = useNavigate();
    const [access, setAccess] = useState({
        vendingMachine: false,
        inventory: false,
        salesReport: false,
        user: false,
        mro: false,
        mroReport: false,
    });

    const getPaymentData = async (clientId) => {
        try {
            const res = await dataProvider.getOne("clients", { id: clientId });
            setPaymentData(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value)
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newPasswordAgain = e.target.value;
        setConfirmPassword(newPasswordAgain);
    };

    const passwordValidation = () => {
        if(!isPasswordValid) {
            return "Please create a password that fulfills the below criteria.";
        } else {
            return undefined;
        }
    };

    const validatePassword = [required("Please enter a secure password."), passwordValidation];
    const validatePasswordAgain = [required("Please re-enter the password."), passwordValidation];

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setAccess((prevAccess) => ({
            ...prevAccess,
            [name]: checked,
        }));
    };

    const handleSave = async (data) => {
        try {
            setUserCreationInProgress(true);

            const originalUser = auth.currentUser;

            const accessArray = [];
            if(access.vendingMachine) accessArray.push("vending_machine");
            if(access.inventory) accessArray.push("inventory");
            if(access.salesReport) accessArray.push("report");
            if(access.user) accessArray.push("user");
            if(access.mro) accessArray.push("mro");
            if(access.mroReport) accessArray.push("mro_report");

            const isAdmin = data.role === "admin";

            let clients = {
                companyName: data.client.companyName || "",
                companyRegNumber: data.client.companyRegNumber || "",
                id: "", //client id
                owner: "", //userid
                ...(isAdmin && {
                    api: {
                        appKey: data.paymentData.api.appKey || "",
                        appSecret: data.paymentData.api.appSecret || "",
                        clients: data.paymentData.api.clients || "",
                        isActive: data.paymentData.api.isActive || false,
                    },
                    paymentMethods: {
                        ipay88: {
                            email: data.client.paymentMethods.ipay88.email || "",
                            isActive: data.client.paymentMethods.ipay88.isActive || "",
                            merchantCode: data.client.paymentMethods.ipay88.merchantCode || "",
                            merchantKey: data.client.paymentMethods.ipay88.merchantKey ||"",
                            phoneNumber: data.client.paymentMethods.ipay88.phoneNumber || "",
                            username: data.client.paymentMethods.ipay88.username || "",
                        }
                    },
                })
            };
            
            const clientRef = await addDoc(collection(db, "clients"), clients);
            const clientId = clientRef.id;

            let users = {
                createdAt: new Date(),
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                role: (data.role == undefined) ? "client" : "admin",
                passwordAgain: data.password,
                access: accessArray,
                client: {
                    companyName: data.client?.companyName || "",
                    id: clientId,
                },
                status: "active",
                updatedAt: new Date(),
            };

            
            const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
            const uid = userCredential.user.uid;
            
            const userRef = await setDoc(doc(db, "users", uid), users);
            
            await updateDoc(doc(db, "clients", clientId), { owner: uid, id: clientId });

            await signOut(auth);

            await auth.updateCurrentUser(originalUser);

            notify("New user added successfully", { type: "success" });
            setUserCreationInProgress(false);
            navigate("/users");
        } catch (error) {
            setUserCreationInProgress(false);
            if(error.code.includes("auth/email-already-in-use")) {
                setEmailError("This email is already in use");
            } else {
                setEmailError("");
            }
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if(userCreationInProgress) {
                return;
            }
            if(user && !userCreationInProgress) {
                await getCurrentUserDetails(user.uid);
            }
        });

        return () => unsubscribe();
    }, [userCreationInProgress]);

    const getCurrentUserDetails = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            if(!res || !res.data) {
                throw new Error("Document not found");
            }
            if(res.data && res.data.client && res.data.client.id) {
                setCurrentRole(res.data.role);
                getPaymentData(res.data.client.id);
                setUserData(res.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    if (currentRole === "client" && userData) {
        return <ClientView record={userData} handleChange={handleChange} access={access} handleSave={handleSave} emailError={emailError} />;
    }


    return (
        <>
            <_extendLayout>
                <CardContent>
                    <CardHeader title="Create User" />
                        <SimpleForm toolbar={null} onSubmit={handleSave}>
                            <Grid container spacing={2}>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <TextInput 
                                        source="firstName" 
                                        label="First Name"
                                        fullWidth
                                        validate={required("Please enter a first name.")}
                                        defaultValue=""
                                    />
                                </Grid>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <TextInput 
                                        source="lastName" 
                                        label="Last Name"
                                        fullWidth
                                        validate={required("Please enter a last name.")}
                                        defaultValue=""
                                    />
                                </Grid>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <TextInput 
                                        source="email" 
                                        label="Email Address"
                                        fullWidth
                                        validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
                                        defaultValue=""
                                        error={!!emailError}  // Checks if there's an error
                                        helperText={emailError}  // Displays the error message
                                        FormHelperTextProps={{ style: { color: 'red' } }}
                                    />
                                </Grid>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <SelectInput
                                        source="role"
                                        label="Role"
                                        fullWidth
                                        choices={[
                                            { name: "Admin", id: "admin" }, 
                                            { name: "Client", id: "client" }
                                        ]}
                                        validate={required("Please select a role.")}
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        defaultValue={role}
                                        onChange={handleRoleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        label="Password"
                                        source="password"
                                        type="password"
                                        fullWidth
                                        value={password || ""}
                                        defaultValue={password || ""}
                                        validate={validatePassword}
                                        onChange={handlePasswordChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        label="Confirm Password"
                                        type="password"
                                        source="password0"
                                        fullWidth
                                        value={confirmPassword || ""}
                                        onChange={handleConfirmPasswordChange}
                                        defaultValue={confirmPassword || ""}
                                        validate={validatePasswordAgain}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                                        minLength={8}
                                        fullWidth
                                        value={password || ""}
                                        valueAgain={confirmPassword || ""}
                                        onChange={(isValid) => {
                                            setIsPasswordValid(isValid);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* if role = client then display as below */}
                            {role == "client" && (
                                <>
                                    <CardHeader title="Client details" />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Company Name"
                                                source="client.companyName"
                                                fullWidth
                                                validate={required("Please enter a company name.")}
                                                defaultValue=""
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Company registration no."
                                                source="companyRegNumber"
                                                fullWidth
                                                validate={required("Please enter a company registration no.")}
                                                defaultValue=""
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextInput
                                                label="Company address"
                                                source="companyAddress"
                                                fullWidth
                                                validate={required("Please enter a company name.")}
                                                defaultValue=""
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="h6">Grant Access</Typography>
                                            <Typography gutterBottom variant="body2">Select to allow user access to modules</Typography>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={access.vendingMachine}
                                                            onChange={(e) => handleChange(e)}
                                                            name="vendingMachine"
                                                        />
                                                    }
                                                    label="Vending Machine"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={access.inventory}
                                                            onChange={(e) => handleChange(e)}
                                                            name="inventory"
                                                        />
                                                    }
                                                    label="Inventory"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={access.salesReport}
                                                            onChange={(e) => handleChange(e)}
                                                            name="salesReport"
                                                        />
                                                    }
                                                    label="Sales Report"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={access.user}
                                                            onChange={(e) => handleChange(e)}
                                                            name="user"
                                                        />
                                                    }
                                                    label="User"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={access.mro}
                                                            onChange={(e) => handleChange(e)}
                                                            name="mro"
                                                        />
                                                    }
                                                    label="MRO"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={access.mroReport}
                                                            onChange={(e) => handleChange(e)}
                                                            name="mroReport"
                                                        />
                                                    }
                                                    label="MRO Report"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>

                                    {/* payment */}
                                    <PaymentMethodForm mode="create" userData={userData} record={paymentData} />
                                    
                                    {/* api setting */}
                                    <CardHeader title="API Setting" />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Machine API App Key"
                                                source="paymentData.api.appKey"
                                                fullWidth
                                                disabled={!userData.isOwner}
                                                defaultValue=""
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Machine API App Secret"
                                                source="paymentData.api.appSecret"
                                                fullWidth
                                                disabled={!userData.isOwner}
                                                defaultValue=""
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                multiline
                                                label="API Clients"
                                                source="paymentData.api.clients"
                                                fullWidth
                                                format={v => (v ? v.join('\n') : '')}
                                                parse={v => (v.trim() ? v.trim().split('\n') : [])}
                                                disabled={!userData.isOwner}
                                                defaultValue=""
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <BooleanInput label="Active" fullWidth source="paymentData.api.isActive" defaultValue="" />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            
                            <Divider />
                            <CardActions>
                                <Button type="submit" variant="contained" color="primary" style={{ marginTop: "2rem"}}>
                                    Save
                                </Button>
                            </CardActions>
                        </SimpleForm>
                </CardContent>
            </_extendLayout>
        </>
    )
};

export default UsersCreate;