import { CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Datagrid, NumberField, TextField, useDataProvider, List, TopToolbar, ExportButton, FunctionField } from 'react-admin';

const EmployeeHistory = ({ employeeId }) => {

    const dataProvider = useDataProvider();

    const [empData, setEmpData] = useState([]);

    useEffect(() => {
        getEmpDetails();
    }, [employeeId]);

    const getEmpDetails = async () => {
        try {
            const res = await dataProvider.getList("transactions", {
                filter: { "employee.id": employeeId, status: 1 },
                sort: { field: "date", order: "DESC" }
            });
            setEmpData(res.data);
        } catch(err) {
            console.log("error: ", err);
        }
    };

    const empExporter = (emps) => {
        const headers = [
            "ID", 
            "Amount",
            "Client",
            "Date",
            "Cost Center",
            "Employee Number",
            "Employee Full Name",
            "Employee ID",
            "Employee Params Tag",
            "Employee Params Value",
            "Error",
            "Machine ID",
            "Machine UUID",
            "Mode",
            "Barcode No",
            "Payment Cash",
            "Payment Cash To Refund",
            "Payment Cash Received Amount",
            "Payment Cash Refund Error Message",
            "Payment Cash Refund Status",
            "Currency",
            "Error Description",
            "Gateway ID",
            "Ipay88 Void Transaction Result",
            "Merchant Code",
            "Merchant Key",
            "Params",
            "Payment Time",
            "Payment Ref No",
            "Payment Refund Amount",
            "Payment Remark",
            "Payment Requery",
            "Payment Status",
            "Payment Terminal",
            "Payment Terminal Sale Detail",
            "Payment Transaction ID",
            "Category ID",
            "Product ID",
            "Product Name",
            "Product Price",
            "Product Slot ID",
            "Quantity",
            "Response Status Data",
            "Response Status Extra Spin Enabled",
            "Response Status Global Dropped Sensor",
            "Response Status Item Dropped",
            "Response Status Item Dropped Sensor",
            "Response Status Locker Opened",
            "Response Status Motor Turned",
            "Response Status No Of Extra Spin",
            "Response Status No Of Item Dropped",
            "Scan Code",
            "Status"
        ];
        const empForExport = emps.map(emp => {
            return {
                "ID": emp.id,
                "Amount": emp.amount,
                "Client": emp.client,
                "Date": emp.date?.toDate ? emp.date?.toDate().toLocaleString() : emp.date?.seconds ? new Date(emp.date?.seconds * 1000).toLocaleString() : "",
                "Cost Center": emp.costCenter ?? "",
                "Employee Number": emp.employeeNumber ?? "",
                "Employee Full Name": emp.employee.fullName ?? "",
                "Employee ID": emp.employee.id ?? "",
                "Employee Params Tag": emp.employeeParamsTag ?? "",
                "Employee Params Value": emp.employeeParamsValue ?? "",
                "Error": emp.error ?? "",
                "Machine ID": emp.machine?.id ?? "",
                "Machine UUID": emp.machine?.machineUUID ?? "",
                "Mode": emp.mode ?? "",
                "Barcode No": emp.barcodeNo ?? "",
                "Payment Cash": emp.paymentCash ?? "",
                "Payment Cash To Refund": emp.paymentCashToRefund ?? "",
                "Payment Cash Received Amount": emp.paymentCashReceivedAmount ?? "",
                "Payment Cash Refund Error Message": emp.paymentCashRefundErrorMessage ?? "",
                "Payment Cash Refund Status": emp.paymentCashRefundStatus ?? "",
                "Currency": emp.currency ?? "",
                "Error Description": emp.errorDesc ?? "",
                "Gateway ID": emp.gatewayId ?? "",
                "Ipay88 Void Transaction Result": emp.ipay88VoidTransactionResult ?? "",
                "Merchant Code": emp.merchantCode ?? "",
                "Merchant Key": emp.merchantKey ?? "",
                "Params": JSON.stringify(emp.params) ?? "",
                "Payment Time": emp.paymentTime ?? "",
                "Payment Ref No": emp.paymentRefNo ?? "",
                "Payment Refund Amount": emp.paymentRefundAmount ?? "",
                "Payment Remark": emp.paymentRemark ?? "",
                "Payment Requery": emp.paymentRequery ?? "",
                "Payment Status": emp.paymentStatus ?? "",
                "Payment Terminal": emp.paymentTerminal ?? "",
                "Payment Terminal Sale Details": emp.paymentTerminalSaleDetails ?? "",
                "Payment Transaction Id": emp.paymentTransactionId ?? "",
                "Category": emp.product?.categoryId ?? "",
                "Product ID": emp.product?.id ?? "",
                "Product Name": emp.product?.name ?? "",
                "Product Price": emp.product?.price ?? "",
                "Product Slot ID": emp.product?.slotId ?? "",
                "Quantity": emp.quantity ?? "",
                "Response Status Data": emp.responseStatus?.data ?? "",
                "Response Status Extra Spin Enabled": emp.responseStatus?.extraSpinEnabled ? "true" : "false",
                "Response Status Global Dropped Sensor": emp.responseStatus?.globalDroppedSensor ? "true" : "false",
                "Response Status Item Dropped": emp.responseStatus?.itemDropped ? "true" : "false",
                "Response Status Item Dropped Sensor": emp.responseStatus?.itemDroppedSensor ? "true" : "false",
                "Response Status Locker Opened": emp.responseStatus?.lockerOpened ? "true" : "false",
                "Response Status Motor Turned": emp.responseStatus?.motorTurned ? "true" : "false",
                "Response Status No Of Extra Spin": emp.responseStatus?.noOfExtraSpin ? "true" : "false",
                "Response Status No Of Item Dropped": emp.responseStatus?.noOfItemDropped ? "true" : "false",
                "Scan Code": emp.scanCode ?? "",
                "Status": emp.status ?? "",
            };
        });

        const csvContent = [
            headers.join(","),
            ...empForExport.map(row => 
                headers.map(header => JSON.stringify(row[header] || "")).join(",")
            )
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transactions.csv");
        link.click();
        URL.revokeObjectURL(url);
    };

    const CustomActions = () => (
        <TopToolbar style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ExportButton label="Export" exporter={empExporter} />
            </div>
        </TopToolbar>
    );

    return (
        <>
            <CardContent>
                {empData.length > 0 ? (
                    <List
                        resource="transactions"
                        basePath="/transactions"
                        bulkActionButtons={false}
                        filter={{ "employee.id": employeeId }}
                        actions={<CustomActions />}
                        sort={{ field: "date", order: "DESC" }}
                    >
                        <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                            <FunctionField
                                label="Date/Time"
                                source="date"
                                render={(record) => {
                                    if(record.date && record.date.seconds) {
                                        const date = new Date(record.date.seconds * 1000 + record.date.nanoseconds / 1000000);
                                        return date.toLocaleString();
                                    }
                                }}
                            />
                            <TextField
                                label="Machine UUID"
                                source="machine.machineUUID"
                            />
                            <TextField
                                label="Product Name"
                                source="product.name"
                            />
                            <TextField
                                label="QTY"
                                source="quantity"
                            />
                            <NumberField
                                source="amount"
                                label="Price (Credit)"
                            />
                        </Datagrid>
                    </List>
                ) : (
                    <p>Loading...</p>
                )}
            </CardContent>
        </>
    )
};

export default EmployeeHistory;