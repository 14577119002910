import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { SelectInput, useDataProvider, required, SimpleForm, useRedirect, minValue, TextInput, useNotify } from 'react-admin';
import { auth, db } from '../../firebase';
import { CardHeader, CardContent, Grid, CardActions, Button } from '@material-ui/core';
import _extendLayout from '../../layout/_extendLayout';
import { doc, updateDoc } from 'firebase/firestore';

const Topup = () => {
    const [employeeChoices, setEmployeeChoices] = useState([]);
    const navigate = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchCurrentUser(user.uid);
            }
        });
        
        return () => checkAuth();
    }, []);

    const fetchCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            fetchEmployeeList(res.data.client.id)
        } catch(err) {
            console.log("error: ", err);
        }
    }

    const fetchEmployeeList = async (clientId) => {
        try {
            const { data: employees } = await dataProvider.getList("employees", {
                filter: { "client": clientId }
            });
            
            const formattedEmployeeList = employees.map((employee) => {
                const id = employee.id;
                const name = employee.fullName || "Unknown Name";
                const cardNumber = employee.cardNumber || "No Card Number";
                return { id, name: `${name}-[${cardNumber}]`};
            });
            setEmployeeChoices(formattedEmployeeList);
        } catch (error) {
            console.log("error: ", error);
        }
    }

    const handleSave = async (data) => {
        const { employee, credit } = data;
        if(!employee || credit == null) {
            return;
        }

        try {
            const employeeRef = doc(db, "employees", employee);
            await updateDoc(employeeRef, {
                credit: credit
            });
            notify("Credit updated successfully", { type: "success" });
            navigate("/employeeTopup");
            setTimeout(() => navigate(0), 1000);
        } catch (error) {
            console.log("error: ", error);
        }
    }

    return (
        <>
            <_extendLayout>
                <CardHeader title="Topup" />
                <CardContent>
                    <p>Search by employee to top-up their credit.</p>
                    <SimpleForm toolbar={null} onSubmit={handleSave}>
                        <Grid container spacing={3}>
                            <Grid item mb={2} xs={12} sm={12} md={6}>
                                <SelectInput
                                    source="employee" // The field name in the form
                                    label="Select Employee"
                                    choices={employeeChoices} // List of employees for the dropdown
                                    optionText="name" // What to display in the dropdown
                                    optionValue="id" // The value submitted (employee ID)
                                    emptyText="Select an employee"
                                    fullWidth
                                    validate={required('Please select a employee.')}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item mb={2} xs={12} sm={12} md={6}>
                                <TextInput
                                    label="Credit"
                                    name="credit"
                                    type="number"
                                    fullWidth
                                    validate={[required('Please enter a credit.'), minValue(0, 'Credit cannot be lower than 0.')]}
                                    variant="outlined"
                                    sx={{ marginTop: "7px" }}
                                />
                            </Grid>
                            <CardActions>
                                <Button type="submit" variant="contained" color="primary">
                                    Top-up
                                </Button>
                            </CardActions>
                        </Grid>
                    </SimpleForm>
                </CardContent>
            </_extendLayout>
        </>
    )
};

export default Topup;
