import React, { useState } from 'react';
import { CardHeader, Chip } from '@material-ui/core';
import { List, Datagrid, TextField, ReferenceField, FunctionField, NumberField, useRecordContext, EditButton, DeleteButton } from 'react-admin';
import { green, grey } from "@material-ui/core/colors";
import EmpActionMenu from './EmpActionMenu';
import SearchInput from '../SearchInput';

const StatusChip = () => {
    const record = useRecordContext();

    if(!record || typeof record.isActive === "undefined") return null;

    const isActive = record.isActive;
    const text = isActive ? "active" : "inactive";
    const color = isActive ? green[500] : grey[600];
    
    return (
        <Chip
            label={text.toUpperCase()}
            style={{
                backgroundColor: color,
                color: "#fff",
                borderRadius: "4px",
                fontWeight: "bold",
            }}
        />
    )
}

const EmployeeList = ({ empList, actions, clientId, isAdmin, ...props }) => {
    const [filteredData, setFilteredData] = useState(empList || []);

    const hanldeFilteredData = (filteredData) => {
        setFilteredData(filteredData)
    };

    const searchFilters = <SearchInput searchKeys={["fullName", "employeeNumber", "cardNumber"]} data={empList} onFilteredData={hanldeFilteredData} />;

    return (
        <>
            <CardHeader title="Employee List" />
            <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
                {empList && (
                    <>
                        <List
                            {...props}
                            resource="employees"
                            basePath="/employees"
                            actions={actions}
                            filter={{ "client": clientId }}
                            data={empList}
                            filters={searchFilters}
                            perPage={25}
                        >
                            <Datagrid data={filteredData} {...props} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                <TextField source="fullName" label="Full Name" />
                                {isAdmin && (
                                    <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                        <TextField source="companyName" />
                                    </ReferenceField>
                                )}
                                <TextField source="employeeNumber" label="Employee No." />
                                <TextField source="cardNumber" label="Card No." />
                                <TextField source="type.name" label="Type" />
                                <TextField source="costCenter" label="Cost Center" />
                                <FunctionField 
                                    label="Current Credit"
                                    render={record => {
                                        if (record && record.credit != null) {
                                          let creditValue = parseFloat(record.credit);
                                          let roundedValue = Math.round(creditValue * 100) / 100;
                                          return roundedValue.toFixed(2);
                                        }
                                        return null;
                                    }}
                                />
                                <NumberField source="recurringCredit" label="Recurring Credit" />
                                <StatusChip label="Status" />
                                <EmpActionMenu label="Actions" basePath="/employees" resource="employees">
                                    <EditButton icon={null} />
                                    <DeleteButton icon={null} />
                                </EmpActionMenu>
                            </Datagrid>
                        </List>
                    </>
                )}
            </div>
        </>
    )
};

export default EmployeeList;
