import React, { forwardRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import ReactRelativeTime from "react-relative-time";
import axios from 'axios';

import "../App.css";

const Notification = forwardRef(({ data, setUnReadCount }, ref) => {
    const navigate = useNavigate();
    const [expandId, setExpandId] = useState(false);
    const [notificationData, setNotificationData] = useState(data);

    let token = localStorage.getItem("token");

    useEffect(() => {
        // Calculate the unread count when data changes
        if (data && data.result && data.result.results) {
            setUnReadCount(data.result.unRead_count);
            console.log("aaaaaaaa, ", data.result.unRead_count)
        }
    }, [data, setUnReadCount]);
    
    //display day date (dd/mm) time (10:35) 
    const formatDate = (date) => {
        let d = new Date(date);
        let day = d.getDate().toString().padStart(2, '0');
        let month = (d.getMonth() + 1).toString().padStart(2, '0');
        let hour = d.getHours().toString().padStart(2, "0");
        let minutes = d.getMinutes().toString().padStart(2, "0");
        return `${day}/${month} ${hour}:${minutes}`;
    };
    
    const handleShowMore = (id) => {
        setExpandId(expandId == id ? null : id);
        //after expand can send a request to update isRead = true
    }

    const handleClick = () => {
        const dataToPass = data;
        navigate("/notifications", { state: dataToPass });
    }

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`http://localhost:5001/api/notification`, {
                params: {
                    skip: 0,
                    limit: 10,
                    authToken: token
                }
            });
            console.log(response.data); // Logs the data from the API
            if(response.data) {
                setNotificationData(response.data)
                setUnReadCount(response.data.result.unRead_count)
            } else {
                console.log("No data found");
                setNotificationData(null)
                setUnReadCount(0)
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const markAllAsRead = async () => {
        try {
            console.log(token)
            const response = await axios.post(
                `http://localhost:5001/api/notification/read?authToken=${token}`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if(response.data.success) {
                alert("Notification updated successfully")
                setUnReadCount(0);
                fetchNotifications();
            } else {
                alert("updating")
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <div ref={ref} className="notification_dd">
                <ul className="notification_ul">
                    <div className="notification_header">
                        Notifications
                        <span className="readAll" onClick={markAllAsRead}>Mark all as read</span>
                    </div>
                    {notificationData && notificationData.result.results && notificationData.result.results.length > 0 ? (
                        notificationData.result.results.map((i) => (
                            <li key={i.notification_id}>
                                <div className="notify_icon">
                                    <span className="icon">
                                        <img className='icon_img' src={i.user_icon} alt='icon' />
                                    </span>
                                </div>
                                <div className={expandId == i.id ? "notify_data_show_all" : "notify_data"}>
                                    <span className="notification_title">
                                        {i.notification_type}
                                    </span>
                                    <span className="notification_sub_title">
                                        &nbsp;{i.notification_content}
                                    </span>
                                    <div className="notification_status">
                                        {i.is_read ? <div></div> : <div className="notify_status"></div>}
                                        <div className="notify_status_time"><ReactRelativeTime value={i.created_dtm} /></div>&nbsp;
                                        {/* <div className="notify_status_time">{formatDate(i.created_dtm)}</div>&nbsp; */}
                                        {/* <div className="notify_show_more" onClick={() => handleShowMore(i.notification_id)}>
                                            {expandId == i.notification_id ? "Show Less" : "Show More"}
                                        </div> */}
                                    </div>
                                </div>
                            </li>
                        ))
                    ): (
                        <div className='App'>No data found</div>
                    )}
                    <li className="show_all">
                        <a onClick={handleClick}>
                            <p>Show Previous Notification</p>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
});

export default Notification
