import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth } from '../firebase';
import { useDataProvider, useNotify, TopToolbar, CreateButton, ExportButton, downloadCSV } from 'react-admin';
import _extendLayout from '../layout/_extendLayout';
import UsersList from "../components/users/UsersList";
import { useLocation } from 'react-router-dom';


const UsersPage = (props) => {
    const [userId, setUserId] = useState(null);
    const [role, setRole] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const notify = useNotify();
    const [companyId, setCompanyId] = useState(null);
    const location = useLocation();

    const dataProvider = useDataProvider();

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                setUserId(user.uid);

                user.getIdTokenResult(true)
                    .then((idTokenResult) => {
                        console.log("token : ", idTokenResult);
                        if (idTokenResult.claims.role) {
                            console.log("role: ", idTokenResult.claims.role)
                            setRole(idTokenResult.claims.role);  // Set role from custom claims
                        } else {
                            setRole(null);
                        }
                        
                        if(location.state && location.state.users) {
                            setUserDetails(location.state.users);
                        } else {
                            getCompanyName(user.uid);
                        }
                    }).catch((error) => {
                        console.error("Error fetching custom claims:", error.message);
                        setRole(null);
                    });
            } else {
                setUserId(null);
                setRole(null);
            }
        });

        return () => checkAuth();
    }, [auth])

    const getCompanyName = async (userId) => {
        console.log("user Id: ", userId)
        const res = await dataProvider.getOne("users", { id: userId })
        let company = res.data.client;
        console.log("company id: ", company.id)
        setCompanyId(company.id);
        fetchUserData(company.id);
    }

    const fetchUserData = async (companyId) => {
        try {
            const res = await dataProvider.getList("users", {
                filter: { "client.id": companyId },
                sort: { field: "createdAt", order: "DESC" },
            });
            setUserDetails(res);
        } catch (err) {
            console.log(err)
        }
    }

    const userExporter = (users) => {
        try{
            const headers = [
                "ID", "Company Name", "Client ID", "Email", "Last Name", "Updated At", "Status",
                "Created At", "First Name", "Access", "Role", "Password Again", "Is Owner",
                "Auth Retry Attempts", "Disabled", "Auth Is Suspended"
            ];
            const usersForExport = users.map(user => ({
                id: user.id || "",
                companyName: user.client.companyName || "",
                clientId: user.client.id || "",
                email: user.email || "",
                lastName: user.lastName || "",
                updatedAt: user.updatedAt?.toDate ? user.updatedAt.toDate().toLocaleString() : user.updatedAt?.seconds ? new Date(user.updatedAt?.seconds * 1000).toLocaleString() : "",
                status: user.status || "",
                createdAt: user.createdAt?.toDate ? user.createdAt.toDate().toLocaleString() : user.createdAt?.seconds ? new Date(user.createdAt?.seconds * 1000).toLocaleString() : "",
                firstName: user.firstName || "",
                access: user.access.join(";"),
                role: user.role || "",
                passwordAgain: user.passwordAgain || "",
                isOwner: user?.isOwner ?? "",
                authRetryAttempt: user?.auth?.retryAttempts ?? "",
                disabled: user?.disabled ?? "",
                authIsSuspended: user?.auth?.isSuspended ?? "",
            }));

            const csvContent = [
                headers.join(","),
                ...usersForExport.map(user => [
                    `"${user.id}"`, `"${user.companyName}"`, `"${user.clientId}"`, `"${user.email}"`, `"${user.lastName}"`, `"${user.updatedAt}"`, `"${user.status}"`, `"${user.createdAt}"`, `"${user.firstName}"`, `"${user.access}"`, `"${user.role}"`, `"${user.passwordAgain}"`, `"${user.isOwner}"`, `"${user.authRetryAttempt}"`, `"${user.disabled}"`, `"${user.authIsSuspended}"`
                ].join(","))
            ].join("\n");

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "users.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.log("export error: ", err);
        }
    }

    const CustomActions = ({ searchInput, ...props }) => (
        <TopToolbar style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            {searchInput}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CreateButton label="Create" />
                <ExportButton label="Export" exporter={userExporter} {...props} />
            </div>
        </TopToolbar>
    );
    

    return (
        <>
            {userDetails ? (
                <_extendLayout>
                    <UsersList userDetails={userDetails} setUserDetails={setUserDetails} {...props} actions={<CustomActions />} companyId={companyId} refreshUserList={() => fetchUserData(companyId)} />
                </_extendLayout>
            ) : (
                <p>Loading...</p>
            )}
        </>
    )
}

export default UsersPage;