import React from "react";

import "../components/css/TransFre.css";

const TransFre = () => {
    return(
        <>
            <div className="totalSalesDiv">
                <div className="totalSalesLabel">
                    Transaction Frequency
                </div>
                <div className="salesValue">
                    8128
                </div>
            </div>
        </>
    )
};

export default TransFre;