import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from 'react'

const ErrorDialog = (props) => {
    const [open, setOpen] = useState(true);
    const arr = props.value.split("; ");
    arr.pop();

    const handleClose = () => {
        setOpen(false);
    }

    const elements = arr.map((value, index) => <li key={index}>{value}</li>);

    let documents = "";
    arr.map(value => {
        documents += value + "\n"
    })

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Upload Unsuccessful! "}
                    <br />
                    {"Please take note of the below errors! "}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ul> {elements} </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CopyToClipboard text={documents} onCopy={() => alert("Copied to Clipboard!")}>
                        <Button variant="outlined" color="primary" autoFocus>
                            Copy
                        </Button>
                    </CopyToClipboard>
                    <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ErrorDialog;
