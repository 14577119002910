import React from "react";
import _extendLayout from "../layout/_extendLayout";
import MachinePerformance from "../components/MachinePerformance";

import "../components/css/Dashboard.css";


const DashboardPage = () => {
    return (
        <>
            <_extendLayout>
                <MachinePerformance />
            </_extendLayout>
        </>
    )
};

export default DashboardPage;