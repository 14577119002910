import { collection, doc, getDocs, getDoc, updateDoc, deleteDoc, addDoc, query, where, orderBy, limit, startAfter } from "firebase/firestore";
import { db } from "./firebase";


const firebaseDataProvider = {
    getList: async (resource, params) => {
        const { filter } = params;
        // const { page, perPage } = pagination;
        // const { field, order } = sort;

        const collectionRef = collection(db, resource);
        let q = query(collectionRef);

        //apply filter
        if(filter) {
            Object.keys(filter).forEach(key => {
                if(filter[key]) {
                    q = query(q, where(key, "==", filter[key]));
                }
            });
        }

        //apply sorting
        // if(field && order) {
        //     q = query(q, orderBy(field, order.toLowerCase()));
        // }

        //apply pagination
        // const offset = (page - 1) * perPage;
        let querySnapshot = await getDocs(q);
        const allDocs = querySnapshot.docs;

        // Apply pagination using limit and startAfter
        // if (offset > 0) {
        //     const lastVisible = allDocs[offset - 1]; // Document to start after
        //     q = query(q, startAfter(lastVisible), limit(perPage));
        // } else {
        //     q = query(q, limit(perPage));
        // }

        // Fetch the paginated documents
        querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        return {
            data,
            total: allDocs.length, // Total count before pagination
        };
    },
    getOne: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            return { data: { id: docSnap.id, ...docSnap.data() } };
        } else {
            throw new Error('Document not found');
        }
    },
    create: async (resource, params) => {
        const collectionRef = collection(db, resource);
        const newDoc = await addDoc(collectionRef, params.data);
        
        return {
            data: { ...params.data, id: newDoc.id },
        };
    },
    update: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        await updateDoc(docRef, params.data);
        
        return {
            data: { ...params.data, id: params.id },
        };
    },
    delete: async (resource, params) => {
        const docRef = doc(db, resource, params.id);
        await deleteDoc(docRef);
        
        return { data: { id: params.id } };
    },
    getMany: async (resource, params) => {
        const docs = await Promise.all(
            params.ids.map(id => getDoc(doc(db, resource, id)))
        );
        const data = docs.map(docSnap => ({
            id: docSnap.id,
            ...docSnap.data(),
        }));
        
        return { data };
    },
}

export default firebaseDataProvider;
