import React, { createContext, useContext, useEffect, useState } from "react";
import AuthLayout from "./AuthLayout";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from 'react-bootstrap/Button';
import Copyright from "../../components/Copyright";

const ResetPasswordContext = createContext();

function ResetPassword(){
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const mode = params.get("mode");
    const actionCode = params.get("oobCode");

    const [state, setState] = useState({
        actionCode: null,
        email: null,
        isSubmit: false,
        isValidLink: false,
        initialized: false,
    });

    function handleRequestComplete() {
        setState({ ...state, isSubmit: true });
    }

    useEffect(() => {
        if(mode !== 'resetPassword') {
            setState(s => ({ ...s, initialized: true, isValidLink: false }));
            return;
        }

        (async function() {
            try {
                const email = await verifyPasswordResetCode(auth, actionCode);
                setState(s => ({ ...s, actionCode, initialized: true, isValidLink: true, email }))
            } catch(e) {
                setState(s => ({ ...s, initialized: true, isValidLink: false }));
            }
        })();
    }, [actionCode, mode]);

    if(!state.initialized) {
        return <AuthLayout heading="Reset your password" subHeading="Loading..." />
    }

    return(
        <ResetPasswordContext.Provider value={{ ...state, handleRequestComplete}}>
            {state.isValidLink ? state.isSubmit ? <Success /> : <ResetPasswordForm /> : <InvalidLink /> }
        </ResetPasswordContext.Provider>
    )
}

function ResetPasswordForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false);
    const { actionCode, handleRequestComplete } = useContext(ResetPasswordContext);

    const message = (
        <span>
            Please enter the new password for your account <i>{email}.</i>
        </span>
    )

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            if(confirmedPassword !== password) {
                return setError("confirmedPassword", null, "Confirm password must match with the new password.");
            }

            setLoading(true);
            await confirmPasswordReset(auth, actionCode, password);
            handleRequestComplete();
        } catch (err) {
            console.log(err.message);
        }
    }

    return(
        <>
            <AuthLayout heading="Reset your password" subHeading={message}>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        autoFocus
                        onChange={ (e) => { setPassword(e.target.value) } }
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmedPassword"
                        label="Confirmed Password"
                        type="password"
                        id="confirmedPassword"
                        autoComplete="confirmedPassword"
                        onChange={ (e) => { setConfirmedPassword(e.target.value) } }
                    />
                    <Button variant="primary" size="lg" type="submit" onClick={onSubmit} style={{ width:"100%", margin:"5% 0%" }}>
                        Reset Password
                    </Button>
                </Box>
            </AuthLayout>
            <Copyright sx={{ mt: 5 }} />
            {/* <AuthLayout heading="Reset your password?" subHeading={message}>
                <div className="forgetPassDiv">
                    <div>
                        <input className="emailInput" type="password" placeholder="New Password" onChange={e => setPassword(e.target.value) } />
                    </div>
                    <div>
                        <input className="emailInput" type="password" placeholder="Confirm Password" onChange={e => setConfirmedPassword(e.target.value) } />
                    </div>
                    <div>
                        <input className="resetButton" type="button" value="Reset Password" onClick={onSubmit} />
                    </div>
                </div>
            </AuthLayout> */}
        </>
        
    );
}

function Success() {
    const { email } = useContext(ResetPasswordContext);
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate(`/?src=resetPassword&e=${email}`)
    };

    return(
        <AuthLayout heading="Password changed successfully" subHeading="Please login again.">
            <Button variant="primary" size="lg" type="submit" onClick={handleLogin} style={{ width:"100%", margin:"5% 0%" }}>
                Login Now
            </Button>
            <Copyright sx={{ mt: 5 }} />
        </AuthLayout>
    );
}

function InvalidLink() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/");
    };

    const handleRequestResetPass = () => {
        navigate("/forgotPassword")
    };

    return(
        <AuthLayout heading="Invalid password reset link" subHeading="Looks like you entered an invalid link or the link has expired. Please try your password again.">
            <Box display="flex" flexDirection="column" className="">
                <Button variant="primary" size="lg" type="submit" onClick={handleBack} style={{ width:"100%", margin:"5% 0%" }}>
                    Go back to home
                </Button>
                <Button variant="secondary" size="lg" type="submit" onClick={handleRequestResetPass} style={{ width:"100%", margin:"5% 0%" }}>
                    Request Reset Password
                </Button>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </AuthLayout>
    )
}



export default ResetPassword; 