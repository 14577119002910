import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AdminContext } from 'react-admin';
import { createTheme } from '@material-ui/core';

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import SignInPage from '../src/pages/auth/SignIn';
import DashboardPage from './pages/Dashboard';
import ForgotPassword from '../src/pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import NotificationsPage from './pages/Notifications';
import firebaseDataProvider from './firebaseDataProvider';
import ProfilePage from './pages/Profile';
import UsersPage from './pages/Users';
import UsersEdit from './components/users/UsersEdit';
import UsersCreate from './components/users/UsersCreate';
import Topup from './pages/Mro.js/Topup';
import EmployeeTypes from './pages/Mro.js/EmployeeTypes';
import EmployeeTypeCreate from './components/EmployeeType/EmployeeTypeCreate';
import EmployeeTypeEdit from './components/EmployeeType/EmployeeTypeEdit';
import Employee from './pages/Mro.js/Employee';
import EmployeeCreate from './components/Employee/EmployeeCreate';
import EmployeeEdit from './components/Employee/EmployeeEdit';


const customTheme = createTheme({
  palette: {
    mode: "light",
  },
})

const customMsg = {
  ra: {
    navigation: {
        page_rows_per_page: 'Rows per page', // Custom label for "Rows per page"
        page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}', // Custom range info
    },
  },
}

const messages = {
  en: { 
    ...englishMessages, 
    ...customMsg ,
    ra: {
      ...englishMessages.ra,
      page: {
        list: "List",
      },
      action: {
        clear_input_value: "Clear input value",
        unselect: 'Unselect',
        sort: "Sort",
        bulk_actions: 'Bulk Actions', // overriding bulk actions
        edit: "Edit",
        delete: 'Delete',
      }
    }
  },
};

// Create the i18nProvider with the merged messages
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en');

const App = () => {
  const AdminApp = () => {
    return(
      <AdminContext dataProvider={firebaseDataProvider} theme={customTheme} i18nProvider={i18nProvider}>
        <Routes>
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/users' element={<UsersPage />} />
          <Route path='/users/create' element={<UsersCreate />} />
          <Route path="/users/edit/:id" element={<UsersEdit />} />
          <Route path="/employeeTopup" element={<Topup />} />
          <Route path="/employeeTypes" element={<EmployeeTypes />} />
          <Route path="/employeeTypes/create" element={<EmployeeTypeCreate />} />
          <Route path="/employeeTypes/edit/:id" element={<EmployeeTypeEdit />} />
          <Route path="/employees" element={<Employee />} />
          <Route path="/employees/create" element={<EmployeeCreate />} />
          <Route path="/employees/edit/:id" element={<EmployeeEdit />} />
        </Routes>
      </AdminContext>
    );
  };
  return (
    <>
        <Router>
          <Routes>
            <Route path="/" element={ <SignInPage /> } />
            <Route path="/dashboard" element={ <DashboardPage /> } />
            <Route path="/ForgotPassword" element={ <ForgotPassword /> } />
            <Route path="/resetPassword" element={ <ResetPassword /> } />
            <Route path="/notifications" element={ <NotificationsPage /> } />
            
            <Route path="/*" element={ <AdminApp /> } />
          </Routes>
        </Router>
    </>
  );
}

export default App;
