import React, { useEffect, useState } from 'react'
import { SimpleForm, TextInput, required, useDataProvider, useNotify, email, SelectInput, BooleanInput } from 'react-admin';
import { Button, CardContent, CardHeader, Grid, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import _extendLayout from '../../layout/_extendLayout';
import PaymentMethodForm from '../PaymentMethodForm';
import { db } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const UsersEdit = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [saving, setSaving] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const navigate = useNavigate();
    
    const [access, setAccess] = useState({
        vendingMachine: false,
        inventory: false,
        salesReport: false,
        user: false,
        mro: false,
        mroReport: false,
        showFailTransaction: false
    });

    useEffect(() => {
        const auth = getAuth();
        const fetchUser = async () => {
            try {
                const data = await dataProvider.getOne("users", { id });
                setUserData(data.data);
                setUserId(data.data.id)
                fetchClientData(data.data.client.id)

                if(data.data.access) {
                    setAccess({
                        vendingMachine: data.data.access.includes("vending_machine") || false,
                        inventory: data.data.access.includes("inventory") || false,
                        salesReport: data.data.access.includes("report") || false,
                        user: data.data.access.includes("user") || false,
                        mro: data.data.access.includes("mro") || false,
                        mroReport: data.data.access.includes("mro_report") || false,
                    })
                };

            } catch (err) {
                notify('Error fetching user data', { type: 'error' });
            }
        };

        fetchUser();
    }, [id, dataProvider, notify]);
    

    const fetchClientData = async (clientId) => {
        try {
            const res = await dataProvider.getOne("clients", { id: clientId });
            setClientData(res.data);
            setClientId(res.data.id);
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setAccess((prevAccess) => ({
            ...prevAccess,
            [name]: checked,
        }));
    };

    const handleSave = async (updatedData) => {
        setSaving(true);
        try {
            const accessArray = [];
            if(access.vendingMachine) accessArray.push("vending_machine");
            if(access.inventory) accessArray.push("inventory");
            if(access.salesReport) accessArray.push("report");
            if(access.user) accessArray.push("user");
            if(access.mro) accessArray.push("mro");
            if(access.mroReport) accessArray.push("mro_report");

            //update users collection
            const userDocRef = doc(db, "users", userId);

            await updateDoc(userDocRef, {
                firstName: updatedData.firstName,
                lastName: updatedData.lasttName,
                email: updatedData.email,
                "client.companyName": updatedData.client.companyName,
                "client.id": clientId,
                access: accessArray
            });
        
            //update clients collection
            const clientDocRef = doc(db, "clients", clientId);
            await updateDoc(clientDocRef, {
                id: clientId,
                companyName: updatedData.client.companyName,
                companyRegNumber: updatedData.companyRegNumber,
                companyAddress: updatedData.companyAddress,
                "paymentMethods.ipay88.merchantCode": updatedData.client.paymentMethods?.ipay88?.merchantCode || "",
                "paymentMethods.ipay88.merchantKey": updatedData.client.paymentMethods?.ipay88?.merchantKey || "",
                "paymentMethods.ipay88.email": updatedData.client.paymentMethods?.ipay88?.email || "",
                "paymentMethods.ipay88.phoneNumber": updatedData.client.paymentMethods?.ipay88?.phoneNumber || "",
                "paymentMethods.ipay88.username": updatedData.client.paymentMethods?.ipay88?.username || "",
                "paymentMethods.ipay88.isActive": updatedData.client.paymentMethods?.ipay88?.isActive || false,
                "api.appKey": updatedData.client.api?.appKey || "",
                "api.appSecret": updatedData.client.api?.appSecret || "",
                "api.clients": updatedData.client.api?.clients || "",
                "api.isActive": updatedData.client.api?.isActive || false,
            });
            
            navigate("/users");
        } catch(err) {
            setSaving(false);
        }
    }

    return (
        <>
            <_extendLayout>
                <CardContent>
                    {userData ? (
                        <SimpleForm
                            onSubmit={handleSave}
                            toolbar={null}
                            key={id}
                        >
                            <CardHeader title="User Details" />
                            <Grid container spacing={2}>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <TextInput 
                                        source="firstName" 
                                        label="First Name"
                                        fullWidth
                                        defaultValue={userData.firstName}
                                        validate={required("Please enter a first name.")}
                                    />
                                </Grid>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <TextInput 
                                        source="lasttName" 
                                        label="Last Name"
                                        fullWidth
                                        defaultValue={userData.lastName}
                                        validate={required("Please enter a last name.")}
                                    />
                                </Grid>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <TextInput 
                                        source="email" 
                                        label="Email Address"
                                        fullWidth
                                        defaultValue={userData.email}
                                        validate={[required("Please enter a valid email."), email("Please enter a valid email.")]}
                                    />
                                </Grid>
                                <Grid item mb={2} xs={12} sm={12} md={6}>
                                    <SelectInput
                                        source="role"
                                        label="Role"
                                        fullWidth
                                        defaultValue={userData.role}
                                        choices={[
                                            { name: "Admin", id: "admin" }, 
                                            { name: "Client", id: "client" }
                                        ]}
                                        optionText="name"
                                        optionValue="id"
                                        disabled={true}
                                        validate={required("Please select a role.")}
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                    />
                                </Grid>
                            </Grid>
                            {clientData && userData.role == "client" && (
                                <>
                                    <CardHeader title="Client Details" />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Company Name"
                                                source="client.companyName"
                                                fullWidth
                                                validate={required("Please enter a company name.")}
                                                disabled={true && !userData.isOwner}
                                                defaultValue={clientData.companyName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Company registration no."
                                                source="companyRegNumber"
                                                fullWidth
                                                validate={required("Please enter a company registration no.")}
                                                disabled={true && !userData.isOwner}
                                                defaultValue={clientData.companyRegNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextInput
                                                label="Company address"
                                                source="companyAddress"
                                                fullWidth
                                                validate={required("Please enter a company name.")}
                                                disabled={true && !userData.isOwner}
                                                defaultValue={clientData.companyAddress}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <>
                                                <Typography gutterBottom variant="h6">
                                                    Grant Access
                                                </Typography>
                                                <Typography gutterBottom variant="body2">
                                                    Select to allow user access to modules
                                                </Typography>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={access.vendingMachine}
                                                                onChange={(e) => handleChange(e)}
                                                                name="vendingMachine"
                                                            />
                                                        }
                                                        label="Vending Machine"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={access.inventory}
                                                                onChange={(e) => handleChange(e)}
                                                                name="inventory"
                                                            />
                                                        }
                                                        label="Inventory"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={access.salesReport}
                                                                onChange={(e) => handleChange(e)}
                                                                name="salesReport"
                                                            />
                                                        }
                                                        label="Sales Report"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={access.user}
                                                                onChange={(e) => handleChange(e)}
                                                                name="user"
                                                            />
                                                        }
                                                        label="User"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={access.mro}
                                                                onChange={(e) => handleChange(e)}
                                                                name="mro"
                                                            />
                                                        }
                                                        label="MRO"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={access.mroReport}
                                                                onChange={(e) => handleChange(e)}
                                                                name="mroReport"
                                                            />
                                                        }
                                                        label="MRO Report"
                                                    />
                                                </FormGroup>

                                                {/* show fail transaction */}

                                            </>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {clientData && (
                                <>
                                    {/* Payment */}
                                    <PaymentMethodForm key="payment" userData={userData} record={clientData} mode="edit" />
                                    
                                    {/* api setting */}
                                    <CardHeader title="Api Setting" />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Machine API App Key"
                                                source="client.api.appKey"
                                                fullWidth
                                                disabled={true && !userData.isOwner}
                                                defaultValue={clientData.api.appKey}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Machine API App Secret"
                                                source="client.api.appSecret"
                                                fullWidth
                                                disabled={true && !userData.isOwner}
                                                defaultValue={clientData.api.appSecret}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                multiline
                                                label="API Clients"
                                                source="client.api.clients"
                                                fullWidth
                                                format={v => (v ? v.join('\n') : '')}
                                                parse={v => (v.trim() ? v.trim().split('\n') : [])}
                                                disabled={true && !userData.isOwner}
                                                defaultValue={clientData.api.clients}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <BooleanInput label="Active" fullWidth source="client.api.isActive" defaultValue={clientData.api.isActive} />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </SimpleForm>
                    ) : (
                        <p>Loading user data...</p>
                    )}
                </CardContent>
            </_extendLayout>
        </>
    )
}

export default UsersEdit;
