import React from "react";
import OverallStatus from "./OverallStatus";
import MachineHealth from "./MachinesHealth";
import LatestTrans from "./LatestTrans";

import "../components/css/RealtimeStatus.css";

const RealtimeStatus = () => {
    return(
        <>
            <div className="realtimeArea">
                <h4 className="lblRealtime">Realtime Status</h4>
                <div className="overallDiv">
                    <OverallStatus />
                </div>
                <div className="machineHealthDiv">
                    <MachineHealth />
                </div>
                <div className="latest-trans-div">
                    <LatestTrans />
                </div>
            </div>
        </>
    );
};

export default RealtimeStatus;