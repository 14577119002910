import { CardContent, CardHeader, Grid, Tabs, Tab, Box, CardActions, Button } from '@material-ui/core';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { BooleanInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import { auth, db } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import _extendLayout from '../../layout/_extendLayout';
import EmployeeHistory from './EmployeeHistory';

const EmployeeEdit = () => {
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const { id } = useParams();
    const [tab, setTab] = useState("details");
    const [empDetails, setEmpDetails] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [client, setClient] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [typeName, setTypeName] = useState(null);
    const [paramsList, setParamsList] = useState([{ tag: "", value: "" }]);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, async(user) => {
            if(user) {
                await getCurrentUser(user.uid);
                await getUser();
            }
            return () => checkAuth();
        })
    }, [auth]);

    const getUser = async () => {
        try {
            const res = await dataProvider.getOne("employees", { id });
            setEmpDetails(res.data);
            setParamsList(res.data.params);
            setTypeName(res.data.type.name);
        } catch(err) {
            console.log("error: ", err);
        }
    };

    const getCurrentUser = async (userId) => {
        try {
            const res = await dataProvider.getOne("users", { id: userId });
            setIsAdmin(res.data.role === "admin");
            setClientId(res.data.client.id);
        } catch (err) {
            console.log("error: ", err);
        }
    };

    const handleAddInputField = () => {
        setParamsList([...paramsList, { tag: "", value: "" }]);
    };

    const handleRemoveInputField = (index) => {
        const list = [...paramsList];
        list.splice(index, 1);
        setParamsList(list);
    };

    const handleInputOnChange = (e, index, field) => {
        const updatedParamsList = [...paramsList];
        updatedParamsList[index] = {
            ...updatedParamsList[index],
            [field]: e.target.value,
        }
        setParamsList(updatedParamsList);
    };

    const handleClientChange = (newClient) => {
        setClient(newClient)
    };

    const getTypeValue = async (e) => {
        try {
            let value = e.target.value;
            const res = await dataProvider.getOne("employeeTypes", { id: value });
            setTypeName(res.data.name.trim());
        } catch(err) {
            console.log("error: ", err);
        }
    };

    const getPreEmployeeDetails = async () => {
        try{
            const res = await dataProvider.getList("employees", {
                filter: { client: clientId },
            });

            if(res.data.length > 0) {
                return res.data;
            } else {
                return null;
            }
        } catch (err) {
            console.log("error: ", err);
        }
    }

    const handleSave = async (data) => {

        const preData = await getPreEmployeeDetails();
        
        let dupScoreCar = 0;
        let dupScoreEmp = 0;

        preData.map(m => {
            if(m.id === id) return;
            if(m.cardNumber === data.cardNumber) dupScoreCar++;
            if(data.employeeNumber && m.employeeNumber === data.employeeNumber) dupScoreEmp++;
        });

        const creditValue = parseFloat(data.credit);
        if(isNaN(creditValue) || creditValue < 0) {
            alert("Credit can only be a number and cannot be less than 0.");
            return;
        }

        const recurringCreditValue = parseFloat(data.recurringCredit)
        if(data.recurringCreditType !== "none" && (isNaN(recurringCreditValue) || recurringCreditValue <= 0)) {
            alert("Recurring credit cannot be less than zero.");
            return;
        }

        if(data.recurringCreditType === "none") data.recurringCredit = 0;
        if(!data.employeeNumber) data.employeeNumber = "";

        if(dupScoreEmp !== 0) {
            alert("Employee Number is taken");
            return;
        }

        if(dupScoreCar !== 0) {
            alert("Card Number is taken");
            return;
        }
        let types = data.type;
        types.name = typeName.trim();

        let emp = {
            cardNumber: data.cardNumber,
            client: (isAdmin) ? data.client : clientId,
            costCenter: data.costCenter || "",
            createdAt: new Date(),
            credit: data.credit,
            email: data.email || "",
            employeeNumber: data.employeeNumber,
            mobile: data.mobile || "",
            fullName: data.fullName,
            isActive: data.isActive,
            params: paramsList,
            recurringCredit: data.recurringCredit,
            recurringCreditType: data.recurringCreditType,
            type: types,
            updatedAt: new Date(),
        }
        const empRef = doc(db, "employees", id);

        await updateDoc(empRef, emp);
        alert("Employee updated");
        navigate("/employees");
    };

    const handleTabsChange = (event, value) => setTab(value);

    return (
        <>
            <_extendLayout>
                <CardHeader title="Edit employee" />
                <Tabs value={tab} onChange={handleTabsChange}>
                    <Tab label="Details" value="details" />
                    <Tab label="History" value="history" />
                </Tabs>
                <Box mt={2}>
                    {tab == "details" && (
                        <CardContent>
                            <p>Employee Details</p>
                            {empDetails ? (
                                <SimpleForm toolbar={null} onSubmit={handleSave}>
                                    <Grid container spacing={3}>
                                        <Grid item mb={2} xs={12} sm={12}>
                                            <TextInput
                                                source="fullName"
                                                label="Full Name"
                                                fullWidth
                                                defaultValue={empDetails.fullName}
                                                validate={required("Please enter a full name.")}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <TextInput
                                                source="cardNumber"
                                                label="Card Number"
                                                fullWidth
                                                defaultValue={empDetails.cardNumber}
                                                validate={required("Please enter a card number.")}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <TextInput
                                                source="employeeNumber"
                                                label="Employee Number"
                                                fullWidth
                                                defaultValue={empDetails.employeeNumber}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <TextInput
                                                source="email"
                                                label="email"
                                                fullWidth
                                                defaultValue={empDetails.email}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <TextInput
                                                source="mobile"
                                                label="Mobile"
                                                fullWidth
                                                defaultValue={empDetails.mobile}
                                            />
                                        </Grid>
                                        {isAdmin ? (
                                            <Grid item mb={2} xs={12} sm={12} md={6}>
                                                <ReferenceInput
                                                    source="client"
                                                    reference="clients"
                                                    filterToQuery={() => {}}
                                                    onChange={(e) => handleClientChange(e.target.value)}
                                                >
                                                    <SelectInput
                                                        optionText="companyName"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="none"
                                                        validate={required("Please select a client")}
                                                    />
                                                </ReferenceInput>
                                            </Grid>
                                        ) : (
                                            <input type='hidden' name="client" value={client} />
                                        )}
                                        {clientId && (
                                            <Grid item mb={2} xs={12} xm={12} md={12}>
                                                <ReferenceInput
                                                    label="Type"
                                                    source="type.id"
                                                    reference="employeeTypes"
                                                    fullWidth   
                                                    filterToQuery={() => {}}
                                                    filter={{ client: clientId }}
                                                    onChange={(e) => getTypeValue(e)}
                                                >
                                                    <SelectInput
                                                        optionText="name"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="none"
                                                        validate={required("Please select a type")}
                                                        defaultValue={empDetails.type?.id}
                                                    />
                                                </ReferenceInput>
                                            </Grid>
                                        )}
                                        <Grid item mb={2} xs={12} sm={12}>
                                            <TextInput
                                                source="costCenter"
                                                label="Cost Center"
                                                fullWidth
                                                defaultValue={empDetails.costCenter}
                                            />
                                        </Grid>
                                        {paramsList?.length > 0 && 
                                            paramsList.map((item, index) => (
                                                <Grid container item xs={12} spacing={3} key={index}>
                                                    <Grid item xs={12} md={5}>
                                                        <TextInput
                                                            label="Tag"
                                                            source={`params[${index}].tag`}
                                                            defaultValue={item?.tag || ""}
                                                            fullWidth
                                                            onChange={e => handleInputOnChange(e, index, "tag")}
                                                            key={`tag-${index}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={5}>
                                                        <TextInput
                                                            label="Value"
                                                            source={`params[${index}].value`}
                                                            defaultValue={item?.value || ""}
                                                            fullWidth
                                                            onChange={e => handleInputOnChange(e, index, "value")}
                                                            key={`value-${index}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Button
                                                            style={{ width: "100%", height: 56 }}
                                                            variant="contained"
                                                            onClick={() => handleRemoveInputField(index)}
                                                        >Remove</Button>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        <Grid item mb={2} xs={12} sm={12}>
                                            <TextInput
                                                source="credit"
                                                label="Current Credit"
                                                fullWidth
                                                defaultValue={empDetails.credit}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <TextInput
                                                source="recurringCreditType"
                                                label="Recurring Credit Type"
                                                fullWidth
                                                defaultValue={empDetails.recurringCreditType}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={6}>
                                            <TextInput
                                                source="recurringCredit"
                                                label="Recurring Credit"
                                                fullWidth
                                                defaultValue={empDetails.recurringCredit}
                                            />
                                        </Grid>
                                        <Grid item mb={2} xs={12} sm={12} md={2}>
                                            <BooleanInput
                                                source="isActive"
                                                label="Active"
                                                defaultValue={empDetails.isActive}
                                            />
                                        </Grid>
                                        <input type="hidden" name="params" value={paramsList} />
                                        <Grid item xs={12} md={12}>
                                            <Button variant="contained" color="primary" onClick={handleAddInputField}>
                                                Add Tag
                                            </Button>
                                        </Grid>
                                        <CardActions>
                                            <Button type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </CardActions>
                                    </Grid>
                                </SimpleForm>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </CardContent>
                    )}
                    {tab === "history" && (
                        <EmployeeHistory employeeId={id} />
                    )}
                </Box>
            </_extendLayout>
        </>
    )
};

export default EmployeeEdit;
