import React, { useEffect, useState } from 'react';
import { email, Edit, SimpleForm, TextInput, required, BooleanInput, ReferenceField, useNotify, useRedirect, useRecordContext, Button, SaveButton, Toolbar, ReferenceInput, useDataProvider } from 'react-admin';
import { Box, CardContent, CardHeader, Grid } from '@material-ui/core';
import PasswordChecklist from "react-password-checklist";
import { db, auth } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { updatePassword } from 'firebase/auth';
import { Alert } from 'react-bootstrap';

const ProfileEdit = ({ userId, userDetails, clientData }) => {
    const redirect = useRedirect();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState(clientData ? clientData.companyName : "");
    const [companyRegNo, setCompanyRegNo] = useState(clientData ? clientData.companyRegNumber : "");
    const [companyAddress, setCompanyAddress] = useState(clientData ? clientData.companyAddress : "");
    const [merchantCode, setMerchantCode] = useState(clientData ? clientData.paymentMethods.ipay88.merchantCode : "");
    const [merchantKey, setMerchantKey] = useState(clientData ? clientData.paymentMethods.ipay88.merchantKey : "");
    const [paymentEmail, setPaymentEmail] = useState(clientData ? clientData.paymentMethods.ipay88.email : "");
    const [paymentNumber, setPaymentNumber] = useState(clientData ? clientData.paymentMethods.ipay88.phoneNumber : "");
    const [paymentUsername, setPaymentUsername] = useState(clientData ? clientData.paymentMethods.ipay88.username : "");
    const [paymentIsActive, setPaymentIsActive] = useState(clientData ? clientData.paymentMethods.ipay88.isActive : false);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    

    const handlePasswordChange = e => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    }

    const handlePasswordAgainChange = e => {
        const newPasswordAgain = e.target.value;
        setPasswordAgain(newPasswordAgain);
    }

    const passwordValidation = e => {
        if(!isPasswordValid) {
            return "Please create a password that fulfills the below criteria.";
        } else {
            return undefined;
        }
    }

    const passwordAgainValidation = () => {
        if(password !== passwordAgain) return "Confirm password must match with the password.";
        else return undefined;
    }

    const validatePassword = [required("Please enter a secure password."), passwordValidation];
    const validatePasswordAgain = [required("Please re-enter the password"), passwordAgainValidation];
    
    const handleEdit = async (updatedRecord) => {
        try {
            //update user password in firebase
            const currentUser = auth.currentUser;
            await updatePassword(currentUser, updatedRecord.passwordAgain)
            console.log("passowrd updated successfully")
            
            //update users collection
            const userDocRef = doc(db, "users", userId);

            await updateDoc(userDocRef, {
                firstName: updatedRecord.firstName,
                lastName: updatedRecord.lastName,
                email: updatedRecord.email,
                "client.companyName": updatedRecord.client.companyName,
                "client.id": updatedRecord.clientId,
            })

            //update clients collection
            const clientDocRef = doc(db, "clients", updatedRecord.clientId);

            await updateDoc(clientDocRef, {
                id: updatedRecord.clientId,
                companyName: updatedRecord.client.companyName,
                companyRegNumber: updatedRecord.companyRegNumber,
                companyAddress: updatedRecord.companyAddress,
                "paymentMethods.ipay88.merchantCode": updatedRecord.paymentMethods.ipay88.merchantCode,
                "paymentMethods.ipay88.merchantKey": updatedRecord.paymentMethods.ipay88.merchantKey,
                "paymentMethods.ipay88.email": updatedRecord.paymentMethods.ipay88.email,
                "paymentMethods.ipay88.phoneNumber": updatedRecord.paymentMethods.ipay88.phoneNumber,
                "paymentMethods.ipay88.username": updatedRecord.paymentMethods.ipay88.username,
                "paymentMethods.ipay88.isActive": updatedRecord.paymentMethods.ipay88.isActive,
            })

            console.log("Record updated successfully");
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
            }, 5000);
            redirect("/profile")
            
        } catch (err) {
            console.log("Error updating : ", err)
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    }
    
    const SaveButtons = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton label="Save" />
            </Toolbar>
        )
    }

    return (
        <>
            {showError && (
                <Alert variant="danger" onClose={() => setShowError(false)} alert-dismissible fade show>
                    Record not updated!
                </Alert>
            )}
            {showSuccess && (
                <Alert variant="success" onClose={() => setShowSuccess(false)} alert-dismissible fade show>
                    Record updated successfully!
                </Alert>
            )}
            {userId && (
                <SimpleForm toolbar={<SaveButtons />} component="form" onSubmit={handleEdit} noValidate>
                    <CardHeader title="Profile Details" />
                    <CardContent className="text-input-width">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput 
                                    fullWidth
                                    label="First name" 
                                    source="firstName" 
                                    validate={required('Please enter a first name.')} 
                                    onChange={ (e) => { setFirstName(e.target.value) } }
                                    defaultValue={userDetails.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput 
                                    fullWidth
                                    label="Last name" 
                                    source="lastName" 
                                    validate={required('Please enter a last name.')} 
                                    onChange={(e) => { setLastName(e.target.value) }}
                                    defaultValue={userDetails.lastName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    label="Email Address"
                                    source="email"
                                    type="email"
                                    validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
                                    disabled
                                    fullWidth
                                    defaultValue={userDetails.email}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardHeader title="Change password" />
                    <CardContent className="text-input-width">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    label="Password"
                                    source="password0"
                                    type="password"
                                    onChange={e => handlePasswordChange(e)}
                                    validate={validatePassword}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    label="Confirm Password"
                                    source="password"
                                    type="password"
                                    onChange={e => handlePasswordAgainChange(e)}
                                    validate={validatePasswordAgain}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    fullWidth
                                    onChange={isValid => {
                                        setIsPasswordValid(isValid)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    {(userDetails.isOwner == true) && (
                        <>
                                <CardHeader title="Company Details" />
                                <CardContent className='text-input-width'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput 
                                                label="Company Name"
                                                source="client.companyName"
                                                fullWidth
                                                defaultValue={clientData.companyName}
                                                validate={required("Please enter a company name")}
                                                onChange={ (e) => { setCompanyName(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                label="Company Registration No."
                                                source="companyRegNumber"
                                                fullWidth
                                                defaultValue={clientData.companyRegNumber}
                                                onChange={ (e) => { setCompanyRegNo(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextInput
                                                label="Company Address"
                                                fullWidth
                                                source="companyAddress"
                                                defaultValue={clientData.companyAddress}
                                                onChange={ (e) => { setCompanyAddress(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextInput
                                                label="Client Id"
                                                source="clientId"
                                                defaultValue={clientData.id}
                                                disabled
                                                fullWidth
                                                style={{ display:"none" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <CardHeader title="Payment Settings" />
                                <CardContent className="text-input-width">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                label="Merchant Code"
                                                fullWidth
                                                source="paymentMethods.ipay88.merchantCode"
                                                defaultValue={clientData.paymentMethods.ipay88.merchantCode}
                                                onChange={ (e) => { setMerchantCode(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                label="Merchant Key"
                                                fullWidth
                                                source="paymentMethods.ipay88.merchantKey"
                                                defaultValue={clientData.paymentMethods.ipay88.merchantKey}
                                                onChange={ (e) => { setMerchantKey(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                label="Email"
                                                fullWidth
                                                source="paymentMethods.ipay88.email"
                                                defaultValue={clientData.paymentMethods.ipay88.email}
                                                onChange={ (e) => { setPaymentEmail(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                label="Phone Number"
                                                fullWidth
                                                source="paymentMethods.ipay88.phoneNumber"
                                                defaultValue={clientData.paymentMethods.ipay88.phoneNumber}
                                                onChange={ (e) => { setPaymentNumber(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput
                                                label="Username"
                                                fullWidth
                                                source="paymentMethods.ipay88.username"
                                                defaultValue={clientData.paymentMethods.ipay88.username}
                                                onChange={ (e) => { setPaymentUsername(e.target.value) } }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {/* <ReferenceField source="client.id" reference="clients" label="Active">
                                                <BooleanInput label="Active" source="paymentMethods.ipay88.isActive" fullWidth />
                                            </ReferenceField> */}
                                            <BooleanInput 
                                                label="isActive"
                                                fullWidth
                                                source="paymentMethods.ipay88.isActive"
                                                defaultValue={clientData.paymentMethods.ipay88.isActive}
                                                onChange={ (e) => { setPaymentIsActive(e.target.value) } }
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>
                        )
                    }
                </SimpleForm>
            )}
        </>
    );
};

export default ProfileEdit;
