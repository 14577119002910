import React from 'react'
import { TextInput, required, email, BooleanInput } from 'react-admin';
import { CardContent, CardHeader, Grid } from '@material-ui/core';

const PaymentMethodForm = ({ userData, record, mode }) => {
    return (
        <>
            <CardHeader title="Payment settings" />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextInput
                        label="Merchant Code"
                        source="client.paymentMethods.ipay88.merchantCode"
                        validate={required('Please enter a merchant code.')}
                        disabled={mode == "edit" && !userData.isOwner}
                        fullWidth
                        defaultValue={record.paymentMethods.ipay88.merchantCode}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        label="Merchant key"
                        source="client.paymentMethods.ipay88.merchantKey"
                        validate={required('Please enter a merchant key.')}
                        disabled={mode == "edit" && !userData.isOwner}
                        fullWidth
                        defaultValue={record.paymentMethods.ipay88.merchantKey}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        label="Email"
                        source="client.paymentMethods.ipay88.email"
                        validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
                        disabled={mode == "edit" && !userData.isOwner}
                        fullWidth
                        defaultValue={record.paymentMethods.ipay88.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        label="Phone number"
                        source="client.paymentMethods.ipay88.phoneNumber"
                        validate={required('Please enter a phone number.')}
                        disabled={mode == "edit" && !userData.isOwner}
                        fullWidth
                        defaultValue={record.paymentMethods.ipay88.phoneNumber}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        label="Username"
                        source="client.paymentMethods.ipay88.username"
                        validate={required('Please enter a username.')}
                        disabled={mode == "edit" && !userData.isOwner}
                        fullWidth
                        defaultValue={record.paymentMethods.ipay88.username}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput label="Active" source="client.paymentMethods.ipay88.isActive" fullWidth defaultValue={record.paymentMethods.ipay88.isActive} />
                </Grid>
            </Grid>
        </>
    )
};

export default PaymentMethodForm;