import React from "react";
import TotalSales from "./TotalSales";
import TransFre from "./TransFreq";
import SalesTrend from "./SalesTrend";
import SalesChart from "./SalesChart";
import RealtimeStatus from "./RealtimeStatus";
import MachinePerTable from "./MachinePerTable";
import ProductsPerTable from "./PorductsPerTable";

import { Dropdown, DropdownButton } from "react-bootstrap";

import { FaDownload } from "react-icons/fa6";

import "../components/css/MachinePerformance.css";


const MachinePerformance = () => {


    return(
        <>
            <div className="container">
                <div className="machineArea"> 
                    <div className="area"> {/* 60% */}
                        <div className="machineTitle">
                            <div>
                                <h4>Machines Performance</h4>
                            </div>
                            <div>
                                <div style={{ display:"flex", alignItems:"center"}}>
                                    <Dropdown>
                                        <DropdownButton variant="primary" id="dropdown-basic" title="Dropdown Button">
                                            <Dropdown.Item href="#action1">Today</Dropdown.Item>
                                            <Dropdown.Item href="#action2">Last 7 Days</Dropdown.Item>
                                            <Dropdown.Item href="#action3">Last 30 Days</Dropdown.Item>
                                            <Dropdown.Item href="#action4">Last 3 Months</Dropdown.Item>
                                            <Dropdown.Item href="#action5">Last 6 Months</Dropdown.Item>
                                            <Dropdown.Item href="#action6">Last 1 Year</Dropdown.Item>
                                        </DropdownButton>
                                    </Dropdown>
                                    <FaDownload style={{ paddingLeft:"1%", width:"30px" }} />
                                </div>
                            </div>
                        </div>
                        <div className="handleSaleTrans">
                            <TotalSales />
                            <TransFre />
                        </div>
                        <div className="handleSaleCharts">
                            <SalesChart />
                            <SalesTrend />
                        </div>
                        <div className="handleSaleCharts">
                            <MachinePerTable />
                            <ProductsPerTable />
                        </div>
                    </div>
                    <div className="realtimeDiv">
                        <RealtimeStatus />
                    </div>
                </div>
            </div>
        </>
    )
};

export default MachinePerformance;