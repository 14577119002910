import { collection, doc, getDoc, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { isEmpty } from "react-admin";
import { db } from "../../firebase";

export const checkField = (tempField, consoleError) => {
    const expectedFields = [
        'id',
        'fullName',
        'cardNumber',
        'employeeNumber',
        'credit',
        'recurringCredit',
        'type.id',
        'type.name',
        'email',
        'mobile',
        'isActive',
        'createdAt',
        'updatedAt',
        'recurringCreditType',
        'costCenter'
    ];

    const field = tempField[0];

    if(field.length > expectedFields.length) {
        consoleError.push("Cannot add extra columns in this document");
    }

    expectedFields.forEach((expectedField, index) => {
        if (field[index] !== expectedField) {
          consoleError.push(`Column ${index + 1} should be ${expectedField}`);
        }
    });

    return consoleError;
};

export const randomIdMaker = (length = 28) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join("");
};

export const convert2string = errors => errors.join("; ");

export const csv2json = async (clientId, tempResult, consoleError) => {
    const jsonObjectId = [];
    const jsonObjectArray = [];
    let count = 2;

    const employeeTypeList = await getEmployeeType(clientId);

    const validators = {
        checkName: (name) => {
            if(isEmpty(name)) {
                consoleError.push(`row ${count} column B: name cannot be left empty`);
                return "";
            }
            return name;
        },
        checkCard: (cardNumber) => {
            if(isEmpty(cardNumber)) {
                consoleError.push(`row ${count} column C: card number cannot be left empty`)
                return "";
            } else if (isNaN(cardNumber)) {
                consoleError.push(`row ${count} column C: card number can only contain digits`)
                return "";
            }
            return cardNumber;
        },
        checkCredit: (credit) => {
            if(isEmpty(credit)) {
                consoleError.push(`row ${count} column E: credit should not be empty`)
                return "";
            } else if (isNaN(credit)) {
                consoleError.push(`row ${count} column E: credit should be all digits`);
                return "";
            }
            return Number(credit);
        },
        checkRecurringCredit: (recurringCredit) => {
            if(isNaN(recurringCredit)) {
                consoleError.push(`row ${count} column F: Recurring credit should be all digits`);
                return "";
            }
            return Number(recurringCredit);
        },
        checkTypeId: (typeId, typeName) => {
            if(!isEmpty(typeId)) return typeId;
            const idFirestore = employeeTypeList.find(employee => employee.type_name === typeName);
            if(!idFirestore) consoleError.push(`row ${count} column G: type.id not found.`);
            return idFirestore ? idFirestore.type_id : "";
        },
        checkTypeName: (typeName) => {
            if(!isEmpty(typeName)) return typeName;
            consoleError.push(`row ${count} column H: type.name cannot be empty.`);
            return "";
        }
    };

    tempResult.forEach(result => {
        const jsonObject = {
            fullName: validators.checkName(result[1]),
            cardNumber: validators.checkCard(result[2]),
            employeeNumber: result[3],
            credit: validators.checkCredit(result[4]),
            recurringCredit: validators.checkRecurringCredit(result[5]),
            client: clientId,
            type: {
                id: validators.checkTypeId(result[6], result[7]),
                name: validators.checkTypeName(result[7]),
            },
            email: result[8],
            mobile: result[9],
            isActive: true,
            updatedAt: serverTimestamp(),
            recurringCreditType: result[13],
            costCenter: result[14],
        };

        jsonObjectId.push(result[0].toString());
        jsonObjectArray.push(jsonObject);
        count++;
    });
    return [jsonObjectId, jsonObjectArray, consoleError];
};

export const getCompanyId = async (clientId) => {
    try {
        const userDoc = doc(db, "users", clientId);
        const snapshot = await getDoc(userDoc);

        if(snapshot.exists()) {
            const data = snapshot.data();
            return data?.client?.id || null;
        } else {
            console.error('No such document!');
            return null;
        }

    } catch(err) {
        console.error('Error getting document:', err);
        return "";
    }
};

export const getEmployeeType = async (companyId) => {
    try {
        const employeeTypeRef = collection(db, "employeeTypes");
        const q = companyId ? query(employeeTypeRef, where("client", "==", companyId)) : employeeTypeRef;
        const employeeSnapshot = await getDocs(q);

        const datas = employeeSnapshot.docs.map(doc => ({
            type_id: doc.id,
            type_name: doc.data().name,
        }));

        return datas;
    } catch (err) {
        console.error("Error fetching employee types:", err);
        return [];
    }
};