import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthLayout from "./AuthLayout";
import Avatar from '@mui/material/Avatar';
import Button from 'react-bootstrap/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import logo from "../../assets/img/sign_in.png";

import { getAuth } from 'firebase/auth';

import Copyright from "../../components/Copyright";

const defaultTheme = createTheme();

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            setLoading(true);
            await signInWithEmailAndPassword(auth, email, password);
            getIdToken();
            //redirect to dashboard
            navigate("/dashboard");
        } catch(err) {
            setLoading(false);
            const { code, message } = err;
            if(code === "auth/invalid-email" || code === "auth/user-disabled" || code === "auth/user-not-found") {
                return setError("email", code, message);
            } else if (code === "auth/wrong-password") {
                return setError("password", code, message);
            } else {
                setError("email", code, message);
                throw error;
            };
        };
    };

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const subHeading = params.get("src") === "resetPassword" ? "Please signin again with your new password." : "Please sign in to your account.";
    const defaultEmail = params.get("e");

    const getIdToken = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
          try {
              // Get the user's ID token
              const idToken = await currentUser.getIdToken(/* forceRefresh */ true); // forceRefresh true forces the refresh of the token
              console.log('ID Token:', idToken);
              //set token to local storage
              localStorage.setItem("token", idToken)
  
              // Now you can use this ID token to authenticate requests to your backend
              return idToken;
          } catch (error) {
              console.error('Error fetching ID token:', error);
          }
      } else {
          console.log('No user is signed in');
      }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
          <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item 
                xs={false}
                sm={false}
                md={7}
                lg={8}
                xl={8}
                sx={{ 
                    backgroundImage:
                        `url(${logo})`,
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                }}
            />
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4} component={Paper} elevation={6} square>
              <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <AuthLayout heading="Welcome Back!" subHeading={subHeading} />
                { error && <p style={{ color:"red",fontSize:"12px" }}>Invalid email or passowrd</p> }
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={ (e) => { setEmail(e.target.value) } }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={ (e) => { setPassword(e.target.value) } }
                  />
                  <Button type="submit" variant="primary" size="lg" style={{ width:"100%", margin:"5% 0%" }}>
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to="/ForgotPassword" variant="body2" style={{ textDecoration:"none" }}>
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
    );
}

export default SignIn;

// const SignInPage = () => {

//     

//     return(
//         <AuthLayout heading="Welcome Back!" subHeading={subHeading}>
//             <div style={{display:"flex", width:"100%" }}>
//                 <div className="login-form" style={{ width: "50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
//                     <div style={{ width: "85%", backgroundColor:"lightgray", padding:"50px 20px" }}>
//                         <div style={{ display: "flex", justifyContent: "center"}}>
//                             <h3>Vechnology Login Portal</h3>
//                         </div>
//                         <div style={{ display: "flex", justifyContent: "center"}}>
//                             <form style={{ width: "70%"}} method="POST">
//                                 <div>
//                                     <div>
//                                         <div>
//                                             <input name="email" type="email" required value={email} placeholder="Email Address" style={{ width:"97%" }} onChange={(e) => setEmail(e.target.value)} />
//                                         </div>
//                                         <div style={{margin:"10px 0px"}}>
//                                             <input name="password" type="password" required value={password} placeholder="Password" style={{width:"97%"}} onChange={(e) => setPassword(e.target.value)} />
//                                             { error && <p style={{ color:"red",fontSize:"12px" }}>Invalid email or passowrd</p> }
//                                         </div>
//                                         <div>
//                                             <a href="/ForgotPassword" style={{ textDecoration:"none", fontSize:"12px", display:"flex", justifyContent:"flex-end" }}>
//                                                 Forget Password
//                                             </a>
//                                         </div>
//                                         <div style={{ margin:"10px 0px" }}>
//                                             <input type="button" onClick={handleSubmit} value="Submit" style={{ width:"100%", color:"white", backgroundColor:"#301934", border:"none", cursor:"pointer", borderRadius:'5px', padding:"5px 0px" }} />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//                 <div style={{ width: "50%" }}>
//                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZvSDGQAD_A2O15L0_G0sAB5icW4p1FzFcdQ&s" className="signInImg" style={{ width: "100%", height: "100%", backgroundSize:"cover" }} />
//                 </div>
//             </div>
//         </AuthLayout>
//     );
// };
//export default SignInPage;