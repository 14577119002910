import React from "react";

import ProgressBarComponent from "./ProgressBar";

import "../components/css/OverallStatus.css";

const OverallStatus = () => {
    return(
        <>
            <h6 className="overallTitle">Overall status</h6>
            <div className="progressArea">
                <ProgressBarComponent progress={40} label={"Online"} />
                <ProgressBarComponent progress={13} label={"Stock"} />
                <ProgressBarComponent progress={100} label={"Machine"} />
            </div>
        </>
    )
};

export default OverallStatus;