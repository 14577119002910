import React, { createContext, useContext, useState } from "react";
import AuthLayout from "./AuthLayout";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';

import Copyright from "../../components/Copyright.js";
import "../../components/css/ForgotPassword.css";

const ForgotPasswordContext = createContext();


function ForgotPassword() {
    const [state, setState] = useState({ email: null, isSubmit: false });

    function handleRequestComplete(email) {
        setState(s => ({ ...s, email: email, isSubmit: true }));
    }
    return (
        <ForgotPasswordContext.Provider value={{ ...state, handleRequestComplete }}>
            {state.isSubmit ? <InstructionSent /> : <ForgotPasswordForm /> }
        </ForgotPasswordContext.Provider>
    );
}

const ForgotPasswordForm = () => {
    const { handleRequestComplete } = useContext(ForgotPasswordContext);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("")
    const [errors, setError] = useState("")
    const navigate = useNavigate();

    const onSubmit = async event => {
        event.preventDefault();
        try{
            setLoading(true);
            await sendPasswordResetEmail(auth, email);
            handleRequestComplete(email);
        } catch(error) {
            setLoading(false);
            if(error.message.includes("user")) {
                console.log("user error")
                return setError("email", error.message);
            }
        }
    }

    const handleRedirect = () => {
        navigate('/');
    }

    return(
        <>
            <AuthLayout heading="Forgot your password" subHeading="Enter your email address to receive the password reset instruction.">
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={ (e) => { setEmail(e.target.value) } }
                    />
                    <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        onClick={onSubmit}
                        style={{ width:"100%" }}
                    >
                        Reset Password
                    </Button>
                    <div className="">
                        <Button
                            type="submit"
                            variant="secondary"
                            size="lg"
                            onClick={handleRedirect}
                            style={{ width:"100%", margin:"5% 0%" }}
                        >
                            Go back to login
                        </Button>
                    </div>
                </Box>
            </AuthLayout>
            <Copyright sx={{ mt: 5 }} />
        </>
    )
}

function InstructionSent() {
    const { email } = useContext(ForgotPasswordContext);
    return (
      <>
        <AuthLayout heading="Please check your email">
            <span>
                We've sent the password reset instruction to <i>{email}</i>.
            </span>
        </AuthLayout>
      </>  
    );
};

export default ForgotPassword; 