import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase';
import { useDataProvider, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import EmployeeList from '../../components/Employee/EmployeeList';
import _extendLayout from '../../layout/_extendLayout';
import EmployeeImport from '../../components/Employee/EmployeeImport';

const Employee = (props) => {
    const dataProvider = useDataProvider();
    
    const [empList, setEmpList] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchCurrentUser(user.uid);
            }

            return () => checkAuth();
        })
    }, [auth])

    const fetchCurrentUser = async (userId) => {
        const res = await dataProvider.getOne("users", { id: userId });
        fetchEmployeeList(res.data.client.id);
        setClientId(res.data.client.id);
        setIsAdmin(res.data.role === "admin");
    };

    const fetchEmployeeList = async (clientId) => {
        const res = await dataProvider.getList("employees", {
            filter: { "client": clientId },
            sort: { field: "createdAt", order: "DESC" },
        });
        setEmpList(res.data);
    };

    const empExporter = (emps) => {
        const headers = [
            "ID", 
            "Full Name", 
            "Card Number", 
            "Employee Number",
            "Credit",
            "Recurring Credit",
            "Type Id",
            "Type Name",
            "Email",
            "Mobile",
            "Active",
            "Created At",
            "Updated At",
            "Recurring Credit Type",
            "Cost Center",
            "Params Tag",
            "Params Value",
        ];

        let csvRows = [headers.join(",")];

        emps.forEach(e => {
            const formattedCreatedAt = e.createdAt?.toDate
                ? `"${e.createdAt.toDate().toLocaleString()}"`
                : e.createdAt?.seconds
                    ? `"${new Date(e.createdAt.seconds * 1000).toLocaleString()}"`
                    : '""';

            const formattedUpdatedAt = e.updatedAt?.toDate
                ? `"${e.updatedAt.toDate().toLocaleString()}"`
                : e.updatedAt?.seconds
                    ? `"${new Date(e.updatedAt.seconds * 1000).toLocaleString()}"`
                    : '""';
            const commonDetails = [
                e.id,
                e.fullName ?? "",
                e.cardNumber ?? "",
                e.employeeNumber ?? "",
                e.credit ?? "",
                e.recurringCredit ?? "",
                e.type?.id ?? "",
                e.type.name ?? "",
                e.email ?? "",
                e.mobile ?? "",
                e.isActive ?? "",
                formattedCreatedAt,
                formattedUpdatedAt,
                e.recurringCreditType ?? "",
                e.costCenter ?? "",
            ];
            if (e.params && e.params.length > 0) {
                // First row with full common details and the first param
                csvRows.push([...commonDetails, e.params[0].tag, e.params[0].value].join(","));
    
                // Subsequent rows for the remaining params with empty common fields
                for (let i = 1; i < e.params.length; i++) {
                    csvRows.push([
                        "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", // Empty common details
                        e.params[i].tag,
                        e.params[i].value
                    ].join(","));
                }
            } else {
                // If no params, just push the common details with empty tag/value fields
                csvRows.push([...commonDetails, "", ""].join(","));
            }
        });

        const csvContent = csvRows.join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "employees.csv";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const CustomActions = ({ searchInput, ...props }) => (
        <TopToolbar style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            {searchInput}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CreateButton label="Create" />
                <ExportButton label="Export" {...props} exporter={empExporter} />
                <EmployeeImport onModalOpenChange={setIsModalOpen} />
            </div>
        </TopToolbar>
    );

    return (
        <>
            {empList ? (
                <_extendLayout isModalOpen={isModalOpen}>
                    <EmployeeList empList={empList} {...props} actions={<CustomActions />} clientId={clientId} isAdmin={isAdmin} />
                </_extendLayout>
            ) : (
                <p>Loading...</p>
            )}
        </>
    )
};

export default Employee;
