import React from "react";

import ProgressBar from 'react-bootstrap/ProgressBar'

import "../components/css/ProgressBar.css";


const ProgressBarComponent = ({ progress, label }) => {

    const getVariant = () => {
        if(progress >= 41) {
            return "success";
        } else if(progress > 20 && progress >= 40) {
            return "warning"
        } else if(progress <= 20) { 
            return "danger";
        } else {
            return "priamry";
        }
    };
    
    
    return (
        <>
            <div className="progress-container">
                <span className="progress-label-start">{label}</span>
                <ProgressBar variant={getVariant()} now={progress} className="custom-progress-bar" />
                <span className="progress-label-end">{progress}%</span>
            </div>
        </>
    )
};

export default ProgressBarComponent;