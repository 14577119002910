import React from "react";

import { Line } from "react-chartjs-2"; 
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

import "../components/css/SalesChart.css";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesChart = () => {
    const chartColors = {
        red: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-red").trim(),
        blue: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-blue").trim(),
        yellow: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-yellow"),
        green: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-green").trim(),
        purple: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-purple").trim(),
        orange: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-orange").trim(),
        borderRed: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-red").trim(),
        borderBlue: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-blue").trim(),
        borderYellow: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-yellow").trim(),
        borderGreen: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-green").trim(),
        borderPurple: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-purple").trim(),
        borderOrange: getComputedStyle(document.documentElement).getPropertyValue("--chart-color-border-orange").trim(),
    };
    const data = {
        labels: ["5", "10", "15", "20", "25", "30"],
        datasets: [
            {
                label: 'Machine 1',
                data: [1599, 1999, 1899, 2799, 5699, 5599],
                backgroundColor: chartColors.red, // Fill color (optional)
                borderColor: chartColors.borderRed, // Custom line color
                borderWidth: 1, // Line width,
                pointStyle: "dash",
            },
            {
                label: "Machine 2",
                data: [0, 4999, 7099, 7199, 4699, 5599],
                backgroundColor: chartColors.blue,
                borderColor: chartColors.borderBlue, // Custom line color
                borderWidth: 1, // Line width
                pointStyle: "dash",
            },
            {
                label: "Machine 3",
                data: [7599, 3999, 5099, 8199, 3699, 3599],
                backgroundColor: chartColors.yellow,
                borderColor: chartColors.borderYellow, // Custom line color
                borderWidth: 1, // Line width
                pointStyle: "dash",
            },
            {
                label: "Machine 4",
                data: [5599, 7999, 8099, 9199, 10699, 9995],
                backgroundColor: chartColors.green,
                borderColor: chartColors.borderGreen, // Custom line color
                borderWidth: 1, // Line width
                pointStyle: "dash",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                // position: "right",
                // align: "end",
                title: {
                    display: true,
                    text: "Dates",
                    color: "gray",
                    textAlign: "right",
                },
            },
            y: {
                // position: "top",
                // align: "start",
                title: {
                    display: true,
                    text: "Sales",
                    color: "gray",
                    textAlign: "left",
                },
            },
        },
        // plugins: {
        //     legend: {
        //         position: "top",
        //     },
        //     scales: {
        //         // x: {
        //         //     display: true,
        //         //     ticks: {
        //         //         display: true,
        //         //         color: 'red',
        //         //         text: "Dates"
        //         //     }
        //         // },
        //         // y: {
        //         //     display: true,
        //         //     ticks: {
        //         //         title: {
        //         //             display: true,
        //         //             color: "#191",
        //         //             text: "Sales",
        //         //         }
        //         //     }
        //         // }
        //         x: {
        //             type: 'time',
        //             time: {
        //               // Luxon format string
        //               tooltipFormat: 'DD T'
        //             },
        //             title: {
        //               display: true,
        //               text: 'Date',
        //               color: "yellow",
        //               padding: 4, 
        //             }
        //         },
        //         y: {
        //             title: {
        //                 display: true,
        //                 text: 'value'
        //             }
        //         },
        //     },
        // },
    };


    return(
        <>
            <div className="salesChartDiv">
                <div>
                    <h6 className="lblSalesChart">Sales Chart</h6>
                </div>

                <div className="chart-wrapper">
                    <Line data={data} options={options} />
                </div>
            </div>
        </>
    )
}

export default SalesChart;