import React from "react";

import Table from "react-bootstrap/Table";

import "../components/css/LatestTrans.css";

const LatestTrans = () => {
    const hotItemsData = [
        {
            "machine":"1",
            "machine_id":"VE000172",
            "transactionDate":"10:11PM 31/12/23",
            "item":"Milo",
            "amount":"RM 3.90",
        },
        {
            "machine":"2",
            "machine_id":"VE000172",
            "transactionDate":"10:06PM 31/12/23",
            "item":"D.Water",
            "amount":"RM 1.50",
        },
        {
            "machine":"3",
            "machine_id":"VE000272",
            "transactionDate":"09:58PM 31/12/23",
            "item":"M.Water",
            "amount":"RM 2.50",
        },
        {
            "machine":"4",
            "machine_id":"VE000234",
            "transactionDate":"09:42PM 31/12/23",
            "item":"B.Coffee",
            "amount":"RM 3.50",
        },
        {
            "machine":"5",
            "machine_id":"VE000172",
            "transactionDate":"09:41PM 31/12/23",
            "item":"Kit Kat",
            "amount":"RM 3.20",
        },
        {
            "machine":"6",
            "machine_id":"VE000234",
            "transactionDate":"09:26PM 31/12/23",
            "item":"Nescafe",
            "amount":"RM 4.50",
        },
        {
            "machine":"7",
            "machine_id":"VE000172",
            "transactionDate":"09:17PM 31/12/23",
            "item":"Milk Tea",
            "amount":"RM 3.90",
        },
    ];

    return(
        <>
            <h6 className="lblLatestTrans">Latest Transactions by All Machine</h6>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <th>Machine</th>
                        <th>Transaction Date</th>
                        <th>Item</th>
                        <th>Amount (RM)</th>
                    </tr>
                </thead>
                <tbody>
                    {hotItemsData.map((i) => (
                        <tr key={i.machine}>
                            <td className="fontHandle">{i.machine_id}</td>
                            <td className="fontHandle">{i.transactionDate}</td>
                            <td className="fontHandle">{i.item}</td>
                            <td className="fontHandle">{i.amount.replace("RM", "").trim()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default LatestTrans;