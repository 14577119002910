import React, { useState } from 'react';
import { ListContextProvider, EmailField, TextField, Datagrid, ResourceContextProvider, useRecordContext, List } from 'react-admin';
import { CardHeader } from '@material-ui/core';
import ActionMenu from '../ActionMenu';
import { Chip } from '@material-ui/core';
import SearchInput from '../SearchInput';

const StatusChip = ({ source }) => {
    const record = useRecordContext();
    if(!record || !record[source]) return null;

    const statusStyles = {
        active: {
            backgroundColor: "#4CAF50",
            color: "#FFF"
        },
        disabled: {
            backgroundColor: "#9E9E9E",
            color: "#FFF"
        }
    }

    const currentStatusStyle = statusStyles[record[source]] || {};

    return (
        <Chip
            label={record[source].toUpperCase()}
            style={{
                ...currentStatusStyle,
                borderRadius: "4px",
                fontWeight: "bold",
                fontSize: "12px",
            }}
        />
    )
}

const CustomActionsMenu = ({ basePath, resource, companyId }) => {
    const record = useRecordContext();
    return (
        <ActionMenu
            basePath={basePath}
            resource={resource}
            record={record}
            label="Actions"
            companyId={companyId}
        />
    );
};


const UsersList = ({ userDetails,setUserDetails, resource = "users", actions, companyId, ...props }) => {
    const [filteredData, setFilteredData] = useState(userDetails?.data || []);

    const hanldeFilteredData = (filteredData) => {
        setFilteredData(filteredData)
    };

    const searchFilters = <SearchInput searchKeys={["firstName", "lastName", "email", "client.companyName"]} data={userDetails.data} onFilteredData={hanldeFilteredData} />;
    return (
        <>
            <CardHeader title="List User" />
            <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
                {userDetails && (
                    <>
                        <List
                            {...props}
                            resource="users"
                            basePath="/users"
                            actions={actions}
                            filter={{ "client.id": companyId }}
                            data={userDetails.data}
                            filters={searchFilters}
                        >
                            <Datagrid data={filteredData} {...props} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                <TextField source="firstName" label="First Name" />
                                <TextField source="lastName" label="Last Name" />
                                <EmailField source="email" label="Email Address" className='no_line' />
                                <TextField source="client.companyName" label="Company Name" />
                                <StatusChip label="Status" source="status" />
                                <CustomActionsMenu label="Actions" basePath={props.basePath} resource={resource} companyId={companyId} />
                            </Datagrid>
                        </List>
                    </>
                )}
            </div>
        </>
    )
}

export default UsersList;
