import React from "react";
import { Container, Typography } from "@mui/material";

const AuthLayout = (props) => {
    const { heading, subHeading, children } = props;

    return(
        <Container component="main" maxWidth="sm" className="" style={{ marginTop: "20px", marginBottom: "20px", width:"100%" }}>
            {heading && (
                <Typography variant="h4" gutterBottom>
                    {heading}
                </Typography>
            )}
            {subHeading && <Typography variant="subtitle1">{subHeading}</Typography>}
            <div className="" style={{ margin: "0px"}}>
                {children}
            </div>
            {/* <div className="">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOD8CQzcq5e3FLzjjaMW1LdpGOKjfJxNyhVA&s" alt="VE Cloud" className="" width="100%" />
                
            </div> */}
            {/* <Box mt="auto">
                <Copyright />
            </Box> */}
        </Container>
    )
};

export default AuthLayout; 