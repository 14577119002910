import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin';
import { auth } from "../../src/firebase";
import { onAuthStateChanged } from 'firebase/auth';
import ProfileEdit from '../components/ProfileEdit';
import _extendLayout from "../layout/_extendLayout";

const ProfilePage = () => {
    const [userId, setUserId] = useState(null);
    const [role, setRole] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [clientData, setClientData] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const checkAuth = onAuthStateChanged(auth, (user) => {
            if(user) {
                setUserId(user.uid);

                user.getIdTokenResult()
                    .then((idTokenResult) => {
                        if (idTokenResult.claims.role) {
                            setRole(idTokenResult.claims.role);  // Set role from custom claims
                        } else {
                            setRole(null);
                        }
                        fetchUserProfile(user.uid);
                    }).catch((error) => {
                        console.error("Error fetching custom claims:", error.message);
                        setRole(null);
                    });
            } else {
                setUserId(null);
                setRole(null);
            }
        });

        return () => checkAuth();
    }, [auth])

    const fetchUserProfile = async (userId) => {
        try {
            console.log("Fetching profile for userID : ", userId)
            const { data } = await dataProvider.getOne("users", { id: userId });
            console.log("Fetched user data", data)
            if (data?.client?.id) {
                fetchClientData(data.client.id);
            }
            setUserDetails(data);
        } catch (err) {
            console.log(err)
        }
    }

    const fetchClientData = async (clientId) => {
        try {
            console.log("Fetching client for client ID : ", clientId)
            const res = await dataProvider.getOne("clients", { id: clientId });
            console.log("Fetched client data", res.data)
            setClientData(res.data);
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <>
            {clientData && userDetails ? (
                <_extendLayout>
                    <ProfileEdit userId={userId} role={role} userDetails={userDetails} clientData={clientData} />
                </_extendLayout>
            ) : (
                <p>Loading data...</p>
            )}
        </>
    )
};

export default ProfilePage;
