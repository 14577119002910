import React, { useEffect, useState } from 'react'
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const SearchInput = ({ searchKeys = [], data, onFilteredData }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        if (Array.isArray(data)) {
            const filteredData = data.filter(item =>
                searchKeys.some(key => {
                    const value = key.split('.').reduce((acc, part) => acc && acc[part], item);
                    return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
                })
            );
            onFilteredData(filteredData);
        } else {
            console.error("SearchInput expected an array of data but received:", data);
        }
    };

    const handleKeyDown = (e) => {
        if(e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <>
            <TextField
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}

export default SearchInput;
