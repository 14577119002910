import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import _extendLayout from '../layout/_extendLayout';
import axios from 'axios';
import ReactRelativeTime from "react-relative-time";

const NotificationsPage = () => {
    const [datas, setDatas] = useState(null);
    const [key, setKey] = useState("all");
    let token = localStorage.getItem("token");

    const markAllAsRead = async () => {
        try {
            const response = await axios.post(
                `http://localhost:5001/api/notification/read?authToken=${token}`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if(response.data.success) {
                alert("Notification updated successfully")
                fetchNotifications();
            } else {
                alert("updating")
            }
        } catch (err) {
            console.log(err)
        }
    }

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`http://localhost:5001/api/notification`, {
                params: {
                    skip: 0,
                    limit: 10,
                    authToken: token
                }
            });
            if(response.data) {
                setDatas(response.data)
            } else {
                setDatas(null)
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const unReadData = (datas && datas.result && datas.result.results.length > 0) ? datas.result.results.filter(a => a.is_read != true) : [];

    
    return (
        <>
            <_extendLayout>
                <div className="notification-container">
                    <div className="notification-selection">
                        <div className="notification-header">
                            <h4 className='text'>Notifications</h4>
                            <div className="more_options" onClick={markAllAsRead}>
                                Mark all as Read
                            </div>
                        </div>
                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="all" title="All">
                                {/* if data is null show no data display */}
                                {datas && datas.result && datas.result.results && datas.result.results.length > 0 ? (
                                    datas.result.results.map((i) => (
                                        <li className='notification-list' key={i.notification_id}>
                                            <div className="notify_icon">
                                                <span className="icon">
                                                    <img className='icon_img' src={i.user_icon} alt='icon' />
                                                </span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", width:"100%" }}>
                                                <div className="text">
                                                    <span className="notification_title">
                                                        <strong>{i.notification_type}</strong> {i.notification_content}
                                                    </span>
                                                    <div className="notification_status">
                                                        <div className="notify_status_time"><ReactRelativeTime value={i.created_dtm} /></div>&nbsp;
                                                    </div>
                                                </div>
                                                {i.is_read == false ? <div className="notify_status"></div> : <></>}
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li className='notification-list'>No data found</li>
                                )}
                            </Tab>
                            <Tab eventKey="unread" title="Unread">
                                {datas && datas.result && datas.result.results && datas.result.results.length > 0 ? (
                                    unReadData.map((i) => (
                                        <li className='notification-list' key={i.notification_id}>
                                            <div className="notify_icon">
                                                <span className="icon">
                                                    <img className='icon_img' src={i.user_icon} alt='icon' />
                                                </span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", width:"100%" }}>
                                                <div className="text">
                                                    <span className="notification_title">
                                                        <strong>{i.notification_type}</strong> {i.notification_content}
                                                    </span>
                                                    <div className="notification_status">
                                                        <div className="notify_status_time"><ReactRelativeTime value={i.created_dtm} /></div>&nbsp;
                                                    </div>
                                                </div>
                                                {i.is_read == false ? <div className="notify_status"></div> : <></>}
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li className='notification-list'>No data found</li>
                                )}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </_extendLayout>
        </>
    )
}

export default NotificationsPage
