import React from "react";
import { Table } from "react-bootstrap";

import "../components/css/LatestTrans.css";

const MachinePerTable = () => {
    const data  = [
        {
            "machine_name": "1",
            "frequency": "2135",
            "totalSales": "RM 10580.20"
        },
        {
            "machine_name": "3",
            "frequency": "2399",
            "totalSales": "RM 8716.40"
        },
        {
            "machine_name": "4",
            "frequency": "1821",
            "totalSales": "RM 6147.30"
        },
        {
            "machine_name": "2",
            "frequency": "1771",
            "totalSales": "RM 4925.50"
        },
    ];

    return(
        <>
            <div className="macTabDiv">
                <h6 className="lblMachineTable">Machine Performance</h6>

                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>Machine</th>
                            <th>Frequency</th>
                            <th>Sales (RM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((items, index) => (
                            <tr key={index}>
                                <td className="fontHandle" key={items.machine_name}>{items.machine_name}</td>
                                <td className="fontHandle" key={items.frequency}>{items.frequency}</td>
                                <td className="fontHandle" key={items.totalSales}>{items.totalSales.replace("RM", "").trim()}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default MachinePerTable;