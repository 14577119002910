import React, { useState } from 'react'
import { ReferenceField, TextField, List, Datagrid, EditButton, DeleteButton } from 'react-admin';
import { CardHeader, CardContent } from '@material-ui/core';
import SearchInput from '../SearchInput';
import GroupField from '../GroupField';

const EmployeeTypeList = ({ employeeType, isAdmin, actions, clientId, ...props }) => {
    const [filteredData, setFilteredData] = useState(employeeType || []);

    const hanldeFilteredData = (filteredData) => {
        setFilteredData(filteredData)
    }

    const searchFilters = <SearchInput searchKeys={["name"]} data={employeeType} onFilteredData={hanldeFilteredData} />;

    return (
        <>
            <CardHeader title="List Types" />
            <CardContent>
                {employeeType && (
                    <>
                        <List 
                            filter={{ "client": clientId }}
                            filters={searchFilters}
                            actions={actions}
                            {...props}
                            resource="employeeTypes"
                        >
                            <Datagrid data={filteredData} sx={{ '& .RaDatagrid-headerCell': { fontWeight: 'bold' } }}>
                                <TextField source="name" label="Employee Type" />
                                <TextField source="id" label="Type Id" />
                                {isAdmin && (
                                    <ReferenceField label="Company" source="client" reference="clients" link={false}>
                                        <TextField source="companyName" />
                                    </ReferenceField>
                                )}
                                <TextField source="credit" label="Credit" />
                                <GroupField>
                                    <EditButton icon={null} to={`/employeeTypes/edit/`} />
                                    <DeleteButton icon={null} />
                                </GroupField>
                            </Datagrid>
                        </List>
                    </>
                )}
            </CardContent>
        </>
    )
}

export default EmployeeTypeList;
